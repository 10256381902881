// notes; when committing, make new branch called:
// ```feature/slicing/homepage```
import { NavLink } from "react-router-dom";
import BaliolaIcon from "./assets/Baliola.svg";
export default function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light ">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img src={BaliolaIcon} alt="Baliola" width="48" height="32" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul
            className="navbar-nav me-auto"
            style={{
              marginLeft: "3rem",
            }}
          >
            <li className="nav-item">
              <NavLink className="nav-link" to="/">
                Beranda
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/Klaim">
                Klaim
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/FAQ">
                FAQ
              </NavLink>
            </li>
          </ul>
          {/* these are buttons */}
          <ul
            className="navbar-nav d-flex justify-content-end align-content-center gap-3
            
          "
            style={{
              marginRight: "3rem",
              width: "100%",
            }}
          >
            <NavLink
              className="btn my-2 my-sm-0 nav-link"
              // yeah bootstrap utility classes
              style={{
                borderRadius: "10px",
                borderColor: "blue",
                padding: "0.7rem 2rem",
                color: "blue",
              }}
              to="/auth/login"
            >
              Login
            </NavLink>
            <NavLink
              className="nav-link btn my-2 my-sm-0"
              style={{
                borderRadius: "10px",
                borderColor: "blue",
                padding: "0.7rem 2rem",
                backgroundColor: "blue",
                color: "white",
              }}
              to="/auth/sign-up"
            >
              Daftar
            </NavLink>
          </ul>
        </div>
      </div>
    </nav>
  );
}
