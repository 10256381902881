import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routes from "./Routes";

export default function PublicRoutes() {
  return (
    <Router basename="/">
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.layout ? (
                <route.layout>
                  <route.component />
                </route.layout>
              ) : (
                <route.component />
              )
            }
          />
        ))}
      </Routes>
    </Router>
  );
}
