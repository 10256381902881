import Auth from "../app/containers/auth/Auth";
// import Layout from "../app/containers/common/layout/Layout";
import NewLayout from "../app/containers/common/layout/newLayout";
// import LandingPage from "../app/containers/home/LandingPage";
import NewLandingPage from "../app/NewLandingPage/NewLandingPage";
import NewFAQPage from "../app/NewFAQPage/NewFAQPage";
// import RegistrationBPJS from "../app/containers/registrationBPJS/RegistrationBPJS";
import Dashboard from "../app/containers/users/dashboard/Dashboard";
import { RouteConfigType } from "./RouteConfig";
import NewClaimPage from "../app/newClaimPage/newClaimPage";

const routes: RouteConfigType[] = [
  {
    path: "/auth/login",
    component: Auth,
  },
  {
    path: "/auth/sign-up",
    component: Auth,
  },
  {
    path: "/auth/forgot-password",
    component: Auth,
  },
  {
    path: "/auth/confirm-password/:token",
    component: Auth,
  },
  {
    path: "/auth/account-type",
    component: Auth,
  },
  {
    path: "/auth/packages-option",
    component: Auth,
  },
  {
    path: "/auth/registration-form/bpu-3-program",
    component: Auth,
  },
  {
    path: "/auth/registration-form/bpu-2-program",
    component: Auth,
  },
  {
    path: "/auth/payment-detail/:paymentId",
    component: Auth,
  },
  {
    path: "/auth/payment-proof",
    component: Auth,
  },
  {
    path: "/",
    component: NewLandingPage,
    layout: NewLayout,
  },
  {
    path: "/klaim",
    component: NewClaimPage,
    layout: NewLayout, // commented out cuz this was causing nav and footer
    // overlap
  },
  {
    path: "/faq",
    component: NewFAQPage,
    layout: NewLayout,
  },
  // {
  //   path: "/registrations_info",
  //   component: RegistrationBPJS,
  //   layout: Layout,
  // },
  {
    path: "/users/dashboard",
    component: Dashboard,
  },
  {
    path: "/users/pembayaran",
    component: Dashboard,
  },
];

export default routes;
