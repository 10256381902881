import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { AuthAPI } from "../../../services/auth/Auth";
import { ToastContainer, toast } from "react-toastify";

export default function ConfirmPassword() {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const navigation = useNavigate();

  const { token } = useParams()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // TODO: Add form validation

    await onResetPass();
  }

  async function onResetPass() {
    setLoading(true)
    try {
      const response = await AuthAPI.ResetPasswordAPI(
        token as string,
        {
          newPassword: password,
          confirmNewPassword: confirmPassword
        });
      const message = response.data.data.message;
      const toastId = toast.success(message, {
        autoClose: 3000
      })
      toast.onChange((toastItem) => {
        if (toastItem.id === toastId && toastItem.status === 'removed') {
          navigation('/auth/login')
        }
      })
    } catch (error: any) {
      const { message } = error.response.data;
      toast.error(message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Group className="" controlId="formBasicEmail">
          <p className="input_label mt-2">Password Baru</p>
          <Form.Control
            required
            className="input_field_pass"
            type="password"
            placeholder="Password"
            name="password"
            onChange={async (e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="" controlId="formBasicEmail">
          <p className="input_label mt-2">Konfirmasi Password</p>
          <Form.Control
            required
            className="input_field_pass"
            type="password"
            placeholder="Password"
            name="password"
            onChange={async (e) => {
              setConfirmPassword(e.target.value)
            }}
          />
        </Form.Group>
        {/* <a className="forgot-pass mt-5" href="/auth/login">
          Kembali ke halaman login ?
        </a> */}

        {!loading ? (
          <Button
            type="submit"
            className="btn_login w-100 mt-3 border-0"
            style={{
              background: "#142657",
              borderRadius: "10px",
              height: "49px",
            }}
          >
            Kirim
          </Button>
        ) : (
          <Button
            type="submit"
            className="btn_login w-100 mt-3 border-0"
            // onClick={onLogin}
            style={{
              background: "#142657",
              borderRadius: "10px",
              height: "49px",
            }}
            disabled
          >
            Loading...
          </Button>
        )}
      </Form>
      <ToastContainer />
    </>
  );
}
