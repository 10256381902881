import React from "react";
import RegistrationForm from "../../../component/common/formRegistration/RegistrationForm";

type RegistrationProps = {
  name: string;
  nik: string;
  email: string;
  phone: string;
};

// BPJS REGISTRATION MEMBER
export default function RegistrationMember(props: RegistrationProps) {
  const { name, nik, email, phone } = props;
  return (
    <div>
      {" "}
      <RegistrationForm name={name} nik={nik} email={email} phone={phone} />
    </div>
  );
}
