import React, { useEffect, useState } from "react";
import jwt from "jsonwebtoken";

import Navbar from "../../../containers/common/navbar/Navbar";
import Footer from "../footer/Footer";
import Sidebar from "../sidebar/Sidebar";
// import { GetToken } from "./app/utils/redux/AuthCRUD";

import arrowRight from "../../../assets/icons/arrowRightIconsCirlce.png";
import "./DashboardLayout.css";
import { GetToken } from "../../../utils/redux/AuthCRUD";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  children: React.ReactNode;
}

const DashboardLayout: React.FC<Props> = ({ title, children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  async function checkToken() {
    const token = await GetToken();
    if (token !== "") {
      console.log("token", token);
      const user = jwt.decode(token);
      console.log(user);
    } else {
      console.log("please login");
      navigate("/auth/login");
    }
  }

  useEffect(() => {
    checkToken();
  });
  const toggleMenu = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  console.log("sidebar open", isSidebarOpen);
  return (
    <div className="container-fluid layout_dashboard">
      <div className="row flex-nowrap">
        <div className="col-auto px-0">
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </div>
        <div
          className={`col ps-md-2 pt-3 ${
            isSidebarOpen
              ? "content_contaier_active"
              : "content_contaier_nonactive"
          }`}
        >
          <button
            // href="#"
            data-bs-target="#sidebar"
            data-bs-toggle="collapse"
            onClick={toggleMenu}
            className="border-0 bg-transparent rounded-3 p-1 text-decoration-none float-end"
            style={{ cursor: "pointer" }}
          >
            <img src={arrowRight} style={{ width: "32px" }} alt="" />
          </button>
          {children}
        </div>{" "}
      </div>
    </div>
  );
};

export default DashboardLayout;
