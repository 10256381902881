import PerisaiIcon from "./assets/Perisai.svg";
import BaliolaIcon from "./assets/Baliola.svg";
export interface AboutUsData {
  label: string;
  content: string;
  image: string;
}

export const allAboutUsData = [
  {
    label: "BPJS Perisai",
    content:
      "BPJS ketenagakerjaan Perisai atau Penggerak Jaminan Sosial Indonesia adalah sistem jaminan yang memastikan akses jaminan sosial bagi pekerja, khususnya yang bekerja secara informal. Sistem Jaminan Sosial Nasional diatur oleh Undang-undang untuk melindungi semua pekerja Indonesia. BPJS Ketenagakerjaan adalah Badan Penyelenggara yang ditugaskan memberikan perlindungan sesuai undang-undang.",
    image: PerisaiIcon,
  },
  {
    label: "Perisai Baliola",
    content:
      "Baliola merupakan perusahaan pengembang dan implementor teknologi blockchain. Baliola Perisai adalah kerjasama antara BPJS Perisai dan Baliola, yang mengimplementasikan teknologi Blockchain dalam proses pendaftaran, perlindungan data nasabah dan juga proses bisnis dari BPJS Perisai. Baliola juga merupakan agen resmi dari BPJS Perisai di Indonesia yang memiliki fokus penyaluran BPJS Perisai ke ekonomi kreatif dan UMKM di Indonesia.",
    image: BaliolaIcon,
  },
];
const [bpjsperisaiinfo, perisaiBaliola] = allAboutUsData;
export const initialTabs = [bpjsperisaiinfo, perisaiBaliola];

export function getNextTab(
  allAboutUsData: AboutUsData[]
): AboutUsData | undefined {
  const existing = new Set(initialTabs);
  return allAboutUsData.find((tab) => !existing.has(tab));
}
