import axios from "axios";
import { config } from "../../../config/config";
import { BPJSRegistrationType } from "../../utils/models/RegistrationModel";
import { GetToken } from "../../utils/redux/AuthCRUD";

export const API_URL =
  config.SERVER_ENV === "staging"
    ? config.API_URL_STAGING
    : config.API_URL_PROD;
const GET_MEMBERS_URL = `${API_URL}/members`;
const GET_MEMBER_URL = `${API_URL}/registrations/member`;
const REGISTER_MEMBER_URL = `${API_URL}/registration`;
const UPLOAD_MEMBER_URL = `${API_URL}/registrations`;
const UPLOAD_PAYMENT_PROOF_URL = `${API_URL}/registrations/payments`;

const GET_DETAIL_TRANSACTION_URL = `${API_URL}/payments`;
const DOWNLOAD_CARD_URL = `${API_URL}/membership-cards/download`;
const INFO_CARD_URL = `${API_URL}/membership-cards/info`;
const MEMBER_INFO_CARD_URL = `${API_URL}/members/dashboard`;

export class Member {
  public static async GetMemberByNikAPI(nik: string) {
    return axios.get(`${GET_MEMBERS_URL}/${nik}`);
  }
  public static async GetMemberAPI() {
    return axios.get(GET_MEMBER_URL, {
      headers: {
        Authorization: `Bearer ${GetToken()}`,
      },
    });
  }
  public static async MemberRegistration(memberData: BPJSRegistrationType) {
    console.log("data", memberData);
    // console.log("program", program);
    return axios.post(REGISTER_MEMBER_URL, memberData, {
      headers: {
        Authorization: `Bearer ${GetToken()}`,
      },
    });
  }
  public static async UploadKTPAPI(uploadData: any, id: string) {
    return axios.post(`${UPLOAD_MEMBER_URL}/${id}/ktp`, uploadData, {
      headers: {
        Authorization: `Bearer ${GetToken()}`,
      },
    });
  }

  public static async UploadPaymentProodAPI(
    uploadData: any,
    paymentId: number
  ) {
    return axios.post(`${UPLOAD_PAYMENT_PROOF_URL}/${paymentId}`, uploadData, {
      headers: {
        Authorization: `Bearer ${GetToken()}`,
      },
    });
  }
  public static async GetDetailTransactionAPI(paymentId: number) {
    return axios.get(`${GET_DETAIL_TRANSACTION_URL}/${paymentId}`, {
      headers: {
        Authorization: `Bearer ${GetToken()}`,
      },
    });
  }
  public static async DownloadCardApi() {
    return axios.get(`${DOWNLOAD_CARD_URL}`, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${GetToken()}`,
      },
    });
  }
  public static async InfoCardApi() {
    return axios.get(`${INFO_CARD_URL}`, {
      headers: {
        Authorization: `Bearer ${GetToken()}`,
      },
    });
  }
  public static async MemberInfoApi() {
    return axios.get(`${MEMBER_INFO_CARD_URL}`, {
      headers: {
        Authorization: `Bearer ${GetToken()}`,
      },
    });
  }
}
