// import KeuntunganIllustration from "./assets/KeuntunganIllustration.svg";
import KeuntunganIllustration from "./assets/NewBenefitsImage.webp";
import Time from "./assets/BenefitsIcons/Time.svg";
import Gears from "./assets/BenefitsIcons/Gears.svg";
import Handshake from "./assets/BenefitsIcons/Handshake.svg";
import Yes from "./assets/BenefitsIcons/Yes.svg";
import KeuntunganUICard from "./KeuntunganUICard";
import { delay, motion, useAnimate, useInView } from "framer-motion";

const KeuntunganData = [
  {
    title: "Mandiri",
    description:
      "Peserta BPJS Perisai tidak harus terdaftar melalui perusahaan. Peserta dapat mendaftarkan dirinya sendiri. ",
    icon: Time,
  },
  {
    title: "Tanggungan Sama",
    description:
      "Tanggungan manfaat dari peserta BPJS Perisai sama dengan manfaat dari BPJS ketenagakerjaan.",
    icon: Handshake,
  },
  {
    title: "Mudah",
    description:
      "Pendaftaran mudah, dapat dilakukan secara online atau dibantu oleh agen dari Baliola Perisai.",
    icon: Yes,
  },
  {
    title: "Pelayanan Sama",
    description:
      "Pelayanan klaim dan lainnya tidak dibedakan antara peserta BPJS Perisai dengan BPJS Ketenagakerjaan yang lainnya.",
    icon: Gears,
  },
];

const Benefits = () => {
  const [scope, animate] = useAnimate();
  const isinView = useInView(scope, { once: true });
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={
        isinView ? { opacity: 1, transition: { delay: 0.5 } } : { opacity: 0 }
      }
      transition={{ duration: 1 }}
      ref={scope}
    >
      <div
        // set the max width of this component to the viewport width
        className="container "
        style={{
          // maxWidth: "100vw",
          // set the padding to 0 on mobile
          padding: "0 1rem",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Keuntungan
        </h1>
        {/* <div
        style={{
          textAlign: "center",
          fontSize: "1.2rem",
        }}
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam
        reiciendis asperiores maiores quidem. Autem nam numquam cupiditate
        minima quod eveniet quis molestias tempore ut laborum.
      </div> */}
        <div
          className="d-flex flex-column flex-lg-row justify-content-center align-items-center mt-5"
          style={{ gap: "2rem" }}
        >
          <img
            src={KeuntunganIllustration}
            alt="..."
            style={{ width: "100%", maxWidth: "543px" }}
          />
          {/* the cards */}
          {/* make this a row with 1 column when mobile */}
          <div className="row row-cols-1 row-cols-md-2 g-4">
            {KeuntunganData.map((data, index) => (
              <motion.div
                // staggered animation in view, wait 2 seconds before starting
                initial={{ opacity: 0 }}
                animate={
                  isinView
                    ? { opacity: 1, transition: { delay: 1 + index * 0.25 } }
                    : { opacity: 0 }
                }
                transition={{ duration: 2 }}
                className="col"
                key={index}
              >
                <div
                  key={index}
                  className="col"
                  style={{
                    transform:
                      index % 2 === 0 ? "translateY(0)" : "translateY(12px)",
                  }}
                >
                  <KeuntunganUICard
                    title={data.title}
                    description={data.description}
                    icon={data.icon}
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Benefits;
