// asset imports
import Klaim1 from "./assets/KlaimPusatPelayananKecelakaanKerjaIllustration.svg";
import Klaim2 from "./assets/KlaimJaminanKematianIllustration.svg";
import Klaim3 from "./assets/KlaimReimburseIllustration.svg";
// each tab info section has its own array of data
export interface InformationSectionData {
  labelTitle: string;
  content: InnerSectionData[];
  image: string;
}

// each inner info section has its own set of data
export interface InnerSectionData {
  innerTitle: string;
  innerIconSrc: string;
  innerContent: string;
}

export const allInformationSectionData: InformationSectionData[] = [
  {
    labelTitle: "Klaim Pusat Pelayanan Kecelakaan Kerja",
    content: [
      {
        innerTitle: "Dasar Hukum",
        innerIconSrc: "assets/images/InformationSection/Icon1.svg",
        innerContent: ` - Undang-Undang Republik Indonesia Nomor : 24 Tahun 2011 tentang Badan Penyelenggara Jaminan Sosial (Lembaran negara Republik Indonesia Tahun 2011 Nomor 116 Tambahan Lembaran Negara Nomor 5256) <br>
- Peraturan Pemerintah Republik Indonesia Nomor 44 Tahun 2015 tentang Penyelenggaraan Program Jaminan Kecelakaan Kerja dan Jaminan Kematian `,
      },
      {
        innerTitle: "Persyaratan",
        innerIconSrc: "assets/images/InformationSection/Icon2.svg",
        innerContent: `- Formulir 3 (Laporan Kecelakaan Tahap <br>
- Formulir 3a (Laporan Kecelakaan Tahap II)<br>
- Formulir 3b (Laporan Kecelakaan Tahap III)<br>
- Kartu BPJS Ketenagakerjaan<br>
- E-KTP<br>
- Kronologis Kejadian Kecelakaan + FC E-KTP 2 saksi<br>
- Laporan kepolisian apabila kecelakaan lalu lintas<br>
- Kwitansi Pengobatan dan Perawatan<br>
- Surat perintah tugas luar/lembur (jika kejadian diluar waktu kerja)<br>
- Fotocopy absensi (jika kasus kecelakaan terjadi pada waktu kerja)<br>
- Buku Tabungan<br>
- NPWP (saldo lebih dari 50 juta)<br>`,
      },
      {
        innerTitle: "Pengecekan Status Klaim",
        innerIconSrc: "assets/images/InformationSection/Icon2.svg",
        innerContent: `1. Buka website <a href="http://www.bpjsketenagakerjaan.go.id/tracking">BPJS Ketenagakerjaan </a><br>
2. Masukkan nomor KPJ<br>
3. Informasi Status Klaim.<br>`,
      },
      {
        innerTitle: "Jangka Waktu dan Biaya Penyelesaian",
        innerIconSrc: "assets/images/InformationSection/Icon2.svg",
        innerContent: `7 hari kerja setelah berkas disetujui.`,
      },
      {
        innerTitle: "Pengaduan",
        innerIconSrc: "assets/images/InformationSection/Icon2.svg",
        innerContent: `- Call center 175<br>
- Facebook: BPJS Ketenagakerjaan<br>
- Twitter: BPJSTKinfo<br>`,
      },
      {
        innerTitle: "Prosedur Layanan",
        innerIconSrc: "assets/images/InformationSection/Icon2.svg",
        innerContent: `- JKK PU<br>
1. Mengisi formulir dan melengkapi dokumen pendaftaran kepesertaan<br>
2. Mengambil nomor antrian untuk klaim JKK<br>
3. Dipanggil oleh petugas melalui mesin antrian<br>
4. Dilayani oleh Petugas<br>
5. Menerima tanda terima klaim<br>
6. Melakukan penilaian kepuasan melalui e-survey<br>
7. Peserta menerima saldo JKK di rekening peserta<br>
- JKK BPU<br>
1. Mengisi formulir dan melengkapi dokumen pendaftaran kepesertaan<br>
2. Mengambil nomor antrian untuk klaim JKK<br>
3. Dipanggil oleh petugas melalui mesin antrian<br>
4. Dilayani oleh Petugas<br>
5. Menerima tanda terima klaim<br>
6. Melakukan penilaian kepuasan melalui e-survey<br>
7. Peserta menerima saldo JKK di rekening peserta<br>`,
      },
      {
        innerTitle: "Formulir Klaim",
        innerIconSrc: "assets/images/InformationSection/Icon2.svg",
        innerContent: `<a href="https://sdsi.co.id/wp-content/uploads/2021/08/JKK-PLKK-ALUR-PROSEDUR-LAYANAN-JAMINAN-KECELAKAAN-KERJA-PADA-RUMAH-SAKIT-PLKK.docx.pdf" target=_blank>Alur Prosedur Layanan </a> <br>
<a href="https://sdsi.co.id/wp-content/uploads/2021/08/JKK-PLKK-F3_Laporan_Kasus_Kecelakaan_Kerja_Tahap_I.pdf" target=_blank>Laporan Kasus Kecelakaan Tahap 1 </a><br>
<a href="https://sdsi.co.id/wp-content/uploads/2021/08/JKK-PLKK-F3_Laporan_Kasus_Kecelakaan_Kerja_Tahap_II.pdf" target=_blank>Laporan Kasus Kecelakaan Tahap 2 </a><br>
<a href="https://sdsi.co.id/wp-content/uploads/2021/08/JKK-PLKK-KRONOLOGIS-KECELAKAAN-LALLU-LINTAS-2-saksi-converted.docx.pdf" target=_blank>Kronologi Kecelakaan </a><br>
<a href="https://sdsi.co.id/wp-content/uploads/2021/08/JKK-PLKK-SURAT-PERNYATAAN-PENJAMINAN-PLKK-word.docx.pdf" target=_blank>Surat Penjaminan PPLK </a><br>`,
      },
      {
        innerTitle: "Contoh Pengisian Formulir Klaim",
        innerIconSrc: "assets/images/InformationSection/Icon2.svg",
        innerContent: `<a href="https://sdsi.co.id/wp-content/uploads/2022/02/Contoh-Laporan-K3-Tahap-I.pdf" target=_blank>Contoh Laporan Kasus Kecelakaan Tahap I </a><br>
<a href="https://sdsi.co.id/wp-content/uploads/2022/02/Contoh-Laporan-K3-Tahap-II.pdf" target=_blank>Contoh Laporan Kasus Kecelakaan Tahap II </a><br>
<a href="https://sdsi.co.id/wp-content/uploads/2022/02/Contoh-Kronologis-Kecelakaan.pdf" target=_blank>Contoh Kronologis Kecelakaan </a><br>`,
      },
    ],
    image: Klaim1,
  },
  {
    labelTitle: "Klaim Jaminan Kematian",
    content: [
      {
        innerTitle: "Dasar Hukum",
        innerIconSrc: "assets/images/InformationSection/Icon1.svg",
        innerContent: `Undang-Undang Republik Indonesia Nomor : 24 Tahun 2011 tentang Badan Penyelenggara Jaminan Sosial (Lembaran negara Republik Indonesia Tahun 2011 Nomor 116 Tambahan Lembaran Negara Nomor 5256)<br>
Peraturan Pemerintah Republik Indonesia Nomor 44 Tahun 2015 tentang Penyelenggaraan Program Jaminan Kecelakaan Kerja dan Jaminan Kematian`,
      },
      {
        innerTitle: "Persyaratan",
        innerIconSrc: "assets/images/InformationSection/Icon2.svg",
        innerContent: `1. Kartu peserta BPJS Ketenagakerjaan<br>
2. Fotokopi E-KTP tenaga kerja dan ahli waris<br>
3. Akta kematian<br>
4. Fotokopi Kartu Keluarga<br>
5. Surat Keterangan ahli waris dari pejabat yang berwenang<br>
6. Buku Nikah (apabila ahli waris merupakan istri/suami sah peserta)<br>
7. Dokumen pendukung lainnya apabila diperlukan`,
      },
      {
        innerTitle: "Pengecekan Status Klaim",
        innerIconSrc: "assets/images/InformationSection/Icon2.svg",
        innerContent: `1. Buka website <a href=">www.bpjsketenagakerjaan.go.id">BPJS Ketenagakerjaan</a><br>
2. Masukkan nomor KPJ<br>
3. Klik Informasi Status Klaim`,
      },
      {
        innerTitle: "Formulir Klaim",
        innerIconSrc: "assets/images/InformationSection/Icon2.svg",
        innerContent: `<a href="https://sdsi.co.id/wp-content/uploads/2021/08/JKM-SYARAT-KLAIM-JKM-2.docx.pdf" target=_blank>Syarat Klaim Jaminan Kematian</a><br/>
<a href="https://sdsi.co.id/wp-content/uploads/2021/08/JKM-formulir-4-2.pdf" target=_blank>Formulir 4</a>`,
      },
      {
        innerTitle: "Contoh Pengisian Formulir Klaim",
        innerIconSrc: "assets/images/InformationSection/Icon2.svg",
        innerContent: `<a href="https://sdsi.co.id/wp-content/uploads/2022/02/Contoh-Formulir-4.pdf" target=_blank>Contoh Formulir 4</a>`,
      },
    ],
    image: Klaim2,
  },
  {
    labelTitle: "Klaim Reimburse",
    content: [
      {
        innerTitle: "Jaminan Kecelakaan Kerja",
        innerIconSrc: "assets/images/InformationSection/Icon1.svg",
        innerContent: `1. Form KK2 (Form Kecelakaan Tahap I) diisi oleh perusahaan dan dilaporkan ke BPJS 
Ketenagakerjaan & Disnakertrans (dilaporkan max 2X24 jam), legalisasi disnakertrans.
2. Surat Pernyataan tidak mengulangi keterlambatan dan bersedia tidak di proses
pengajuannya bila dikemudian hari terjadi pengulangan keterlambatan pelaporan, dengan
tembusan ke disnaker setempat (Bila Perusahaan melaporkan kejadian kecelakaan lebih
dari 2×24 jam).<br>
3. Form KK3 (Form Kecelakaan Tahap II) diisi oleh perusahaan (biaya2 s/d selesai
perawatan) -> Diisi Lengkap.<br>
4. Formulir KK4 (Form Pemeriksaan Dokter)  diisi oleh RS/Dokter yang merawat
(setelah selesai perawatan) -> Diisi Lengkap`,
      },
      {
        innerTitle: "Persyaratan Lain",
        innerIconSrc: "assets/images/InformationSection/Icon2.svg",
        innerContent: `1. FC Kartu Peserta Jamsostek/BPJS Ketenagakerjaan (KPJ/ KPB) TK<br>
2. FC KTP Tenaga Kerja<br>
3. FC Absensi/ Daftar Hadir Tenaga Kerja pada saat kecelakaan s/d kembali bekerja<br>
4. Kecelakaan Lalu Lintas (Di lampirkan Berita Acara dari Kepolisian POLRES Setempat):<br>
a. Bila Kecelakaan terjadi di lalu lintas dan merupakan Kecelakaan Tunggal maka
dilampirkan Berita Acara Kecelakaan ditandatangani oleh 2 orang saksi.<br>
b. Bila Kecelakaan terjadi di lalu lintas merupakan Kecelakaan Ganda (lebih dari 1
pengendara) maka pengajuan klaim di ajukan terlebih dahulu ke PT Jasa Raharja,
Kemudian di lampirkan Surat Penjamin Pertama dan Bukti pembayaran.<br>
c. Untuk kecelakaan lalu lintas di sertai Keterangan Kronologis dari perusahaan
yang menjelaskan Alamat Peserta , Alamat Perusahaan , Lokasi Kejadian
Kecelakaanup<br>
5. Kecelakaan di dalam Ruangan:
a. Dilampirkan Kronologis Kejadian dari perusahaan<br>
6. Surat Dinas/ Surat Jalan: untuk kecelakaan kerja pada saat dinas luar<br>
7. Surat Perintah Lembur untuk kecelakaan pada saat lembur<br>
8. Kwitansi² asli bermaterai + rincian pengobatan/resep obat & perawatan (untuk klaim
Rp. 250 rb s/d 1 jt ke atas) -> untuk kwitansi di atas 250 ribu materai 3000, untuk
        kwitansi diatas 1 juta materai 6000.<br>
9. Surat Istirahat Dokter (atas indikasi medis yg berhubungan dengan kasus kecelakaan)`,
      },
      {
        innerTitle: "Formulir Klaim",
        innerIconSrc: "assets/images/InformationSection/Icon2.svg",
        innerContent: `<a href="https://sdsi.co.id/wp-content/uploads/2021/08/JKK-PLKK-F3_Laporan_Kasus_Kecelakaan_Kerja_Tahap_I.pdf" target=_blank>Laporan Tahap 1<a><br>
<a href="https://sdsi.co.id/wp-content/uploads/2021/08/JKK-PLKK-F3_Laporan_Kasus_Kecelakaan_Kerja_Tahap_II.pdf" target=_blank>Laporan Tahap 2<a><br>
<a href="https://sdsi.co.id/wp-content/uploads/2021/08/FORMULIR-DOKTER-KK4.pdf" target=_blank>Form Dokter KK4<a><br>
<a href="https://sdsi.co.id/wp-content/uploads/2021/08/KRONOLOGIS-KECELAKAAN-LALLU-LINTAS-2-saksi.pdf" target=_blank>Kronologis Kecelakaan<a><br>
<a href="https://sdsi.co.id/wp-content/uploads/2021/08/LAMPIRAN-DATA-PENDUKUNG-JKK-REIMBURST.pdf" target=_blank>Lampiran Data Pendukung<a><br>
<a href="https://sdsi.co.id/wp-content/uploads/2021/08/PENYEMPURNAAN-TATA-CARA-PENGAJUAN-KLAIM-DAN-KELENGKAPAN-DATA-PENDUKUNG.pdf" target=_blank>Penyempurnaan Tata Cara<a>`,
      },
      {
        innerTitle: "Contoh Pengisian Formulir Klaim",
        innerIconSrc: "assets/images/InformationSection/Icon2.svg",
        innerContent: `<a href="https://sdsi.co.id/wp-content/uploads/2022/02/Contoh-Laporan-K3-Tahap-I.pdf" target=_blank>Contoh Laporan Kasus Kecelakaan Tahap I</a><br>
<a href="https://sdsi.co.id/wp-content/uploads/2022/02/Contoh-Laporan-K3-Tahap-II.pdf" target=_blank>Contoh Laporan Kasus Kecelakaan Tahap II</a><br>
<a href="https://sdsi.co.id/wp-content/uploads/2022/02/Contoh-Kronologis-Kecelakaan.pdf" target=_blank>Contoh Kronologis Kecelakaan</a>`,
      },
    ],
    image: Klaim3,
  },
];

const [tab1, tab2, tab3] = allInformationSectionData;
export const initialTabs = [tab1, tab2, tab3];

export function getNextTab(
  allInformationSectionData: InformationSectionData[]
): InformationSectionData | undefined {
  const existing = new Set(initialTabs);
  return allInformationSectionData.find((tab) => !existing.has(tab));
}
