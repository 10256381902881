import { useNavigate } from "react-router-dom";

export async function setAuthLocal(token: string) {
  const data = {
    token: token,
  };
  const storeToken = await localStorage.setItem("token", JSON.stringify(data));
}
export function GetToken() {
  let token = "";
  if (localStorage.length !== 0) {
    const localStorageData = JSON.parse(localStorage.getItem("token") || "");
    token = localStorageData.token;
    return token;
  } else {
    console.log(" no token ", token);
    return token;
  }
}
