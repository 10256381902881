import { NavLink } from "react-router-dom";
import Baliolaicon from "./assets/Baliola.svg";
const Footer = () => (
  <footer
    style={{
      marginTop: "8rem",
    }}
  >
    <hr />
    <img
      src={Baliolaicon}
      alt="Baliola"
      style={{ width: "3rem", height: "3rem", marginBottom: "1rem" }}
      className="mx-auto d-flex"
    />
    <h1 className="mt-3 mb-5 text-center">PERISAI BALIOLA</h1>
    <hr />
    <nav
      className="d-flex flex-column align-items-center justify-content-center"
      style={{
        flexBasis: "max-content",
        overflowX: "clip",
      }}
    >
      <ul
        className="d-flex flex-row justify-content-center"
        style={{
          width: "100vw",
        }}
      >
        <li
          style={{
            maxWidth: "max-content",
          }}
        >
          <a
            className="text-dark text-decoration-none "
            style={{ fontSize: "clamp(8px, 2vw, 16px)" }}
            href="#aboutus"
          >
            Tentang Kami
          </a>
        </li>
        {/* <li
          style={{
            maxWidth: "max-content",
          }}
        >
          <NavLink
            className="text-dark text-decoration-none"
            style={{ fontSize: "clamp(8px, 2vw, 16px)" }}
            to="/test"
          >
            Menu
          </NavLink>
        </li> */}
        <li
          style={{
            maxWidth: "max-content",
          }}
        >
          <NavLink
            className="text-dark text-decoration-none"
            style={{ fontSize: "clamp(8px, 2vw, 16px)" }}
            to="/auth/login"
          >
            Login Member
          </NavLink>
        </li>
        <li
          style={{
            maxWidth: "max-content",
          }}
        >
          <NavLink
            className="text-dark text-decoration-none"
            style={{ fontSize: "clamp(8px, 2vw, 16px)" }}
            to="/auth/sign-up"
          >
            Daftar Peserta BPJS
          </NavLink>
        </li>
        <li
          style={{
            maxWidth: "max-content",
          }}
        >
          <a
            className="text-dark text-decoration-none"
            style={{ fontSize: "clamp(8px, 2vw, 16px)" }}
            href="https://mandalachain.io"
          >
            Mandalachain
          </a>
        </li>
      </ul>
    </nav>
    <hr />
    {/* Copyright Text */}
    <p className="text-center" style={{ fontSize: "0.75rem" }}>
      Copyright © 2023. All Rights Reserved. Powered by Kepeng Indonesia
    </p>
  </footer>
);

export default Footer;
