export type LoginType = {
  nik: string;
  password: string;
};

export interface LoginModel {
  loginData: LoginType;
}

export const defaultLogin: LoginModel = {
  loginData: {
    nik: "",
    password: "",
  },
};
