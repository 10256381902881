import React from "react";
import BPJSCard from "../../../../component/users/card/BPJSCard";
import CopyIcon from "../../../../assets/icons/CopyIcon.png";
import "./Account.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Data } from "../Dashboard";
import { Member } from "../../../../services/registration/Member";
import axios from "axios";

export default function NewAccountPage(props: any) {
  const { userData, cardData } = props;
  console.log("user data", userData);

  async function DownloadCard() {
    await Member.DownloadCardApi()
      .then((response) => {
        // console.log("response", response);
        console.log(`response headers: ${response.headers}`);
        const contentType = response.headers["content-type"];
        if (contentType === "image/png") {
          downloadFile(response);
        } else {
          console.error("Invalid Content-Type header");
        }
        // FileSaver.saveAs(blob, `${props.name}.xlsx`);
        // setMemberData(memberDetail);
      })
      .catch((error) => {
        console.log("error", error.response);
        const { message } = error;
        console.log("message", message);
        // Handle API error
        if (axios.isAxiosError(error) && error) {
          console.error("API error:", message);

          alert(message);
        } else {
          console.error("Other error:", error);
          alert(message);
        }
      });
  }
  function downloadFile(response: any) {
    const filename = `Data calon peserta ${props.name}.png`;
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
    alert("Download kartu berhasil");
  }

  return (
    <div className="detail_account_container show">
      <div className="d-flex flex-xl-row flex-column gap-md-4 gap-xxl-0">
        <div className="mt-5 col-xl-6 col-12 ">
          <BPJSCard cardData={cardData} />
        </div>
        <div className="mt-5 col-xl-6 col-12 d-flex flex-column gap-1">
          <p className="detail_card_title">Informasi Kartu</p>
          <div className="row d-flex flex-row my-auto ">
            <p className="col-5">ID Kartu : </p>{" "}
            <p className="col-md-5 col-6 detail_card">
              {userData?.registrationStatus !== "VERIFIED"
                ? "-"
                : cardData?.membershipCard?.cardNo}
            </p>{" "}
          </div>
          <div className="row d-flex flex-row my-auto">
            <p className="col-md-5 col-6">Pemilik Kartu : </p>{" "}
            <p className="col-md-5 col-6 detail_card">
              {userData?.registrationStatus !== "VERIFIED"
                ? "-"
                : cardData?.membershipCard?.holder}
            </p>{" "}
          </div>
          <div className="row d-flex flex-row my-auto">
            <p className="col-md-5 col-6">Status : </p>{" "}
            {userData?.registrationStatus === "PENDING" && (
              <p className="col-md-5 col-6 status_card_pending">
                Sedang dalam verifikasi
              </p>
            )}
            {userData?.registrationStatus === null && (
              <p className="col-md-5 col-6 status_card_failed">
                Belum Melakukan Pendaftaran
              </p>
            )}
            {userData?.registrationStatus === "REJECTED" && (
              <p className="col-md-5 col-6 status_card_failed">Ditolak</p>
            )}
            {userData?.registrationStatus === "UNPAID" && (
              <p className="col-md-5 col-6 status_card_failed">
                Menunggu Pembayaran
              </p>
            )}
            {userData?.registrationStatus === "VERIFIED" && (
              <p className="col-md-5 col-6 status_card_success">Active</p>
            )}
          </div>
          <div className="row d-flex flex-row my-auto">
            <p className="col-md-5 col-6">Masa Berlaku : </p>{" "}
            <p className="col-md-5 col-6 detail_card">
              {" "}
              {userData?.registrationStatus !== "VERIFIED"
                ? "-"
                : cardData?.membershipCard?.validUntil}
            </p>{" "}
          </div>
          {userData?.registrationStatus === "VERIFIED" && (
            <div className="row d-flex flex-row my-auto col-xl-4 col-8 ps-2">
              <Button
                variant="primary border-0"
                type="submit"
                onClick={DownloadCard}
                style={{
                  background: "#142657",
                  borderRadius: "10px",
                  height: "49px",
                }}
              >
                Download Kartu
              </Button>
            </div>
          )}
        </div>
      </div>
      {userData?.registrationStatus === "UNPAID" && (
        <div className="alert alert-danger mt-5 w-auto" role="alert">
          Mohon segera melakukan pembayaran agar pesanan Anda dapat segera
          diproses."{" "}
          <Link
            to={`/auth/payment-detail/${userData?.unpaidPayment?.unpaidPayment?.paymentId}`}
            className="alert-link"
          >
            Lihat detail pembayaran!
          </Link>
        </div>
      )}

      {userData?.registrationStatus === "PENDING" && (
        <div className="alert alert-warning mt-5 w-auto" role="alert">
          Maaf, akun Anda belum terverifikasi. Admin sedang melakukan
          pemeriksaan terhadap bukti pembayaran yang Anda kirimkan.
        </div>
      )}
      {userData?.registrationStatus === null && (
        <div className="alert alert-danger mt-5 w-auto" role="alert">
          Mohon segera melanjutkan proses pendaftaran diproses."{" "}
          <Link to={`/auth/packages-option`} className="alert-link">
            Daftar!
          </Link>
        </div>
      )}
      {userData?.registrationStatus === "REJECTED" && (
        <div className="alert alert-danger mt-5 w-auto" role="alert">
          Proses pendaftaran akun anda ditolak. Mohon lakukan pendaftaran ulang.{" "}
          <Link to={`/auth/packages-option`} className="alert-link">
            Daftar!
          </Link>
        </div>
      )}
      {userData?.registrationStatus === "VERIFIED" && (
        <div
          className="alert alert-success alert-dismissible fade show mt-5"
          role="alert"
          // style={{ maxWidth: "1140px" }}
        >
          <p>
            Selamat! Akun Anda telah berhasil <strong>terverifikasi</strong> .
          </p>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      )}
      <div className="d-flex flex-column mt-2 gap-2">
        <p className="detail_card_title">Data Diri</p>
        <div className="d-flex flex-column flex-xl-row col-12">
          <div className="mt-1 col-xl-6 col-12 d-flex flex-column gap-2">
            <div className="row d-flex flex-row my-auto">
              <p className="col-md-5 col-6">NIK : </p>{" "}
              <p className="col-md-5 col-6 detail_card">
                {userData?.personalInfo?.nik
                  ? userData?.personalInfo?.nik
                  : "-"}
              </p>{" "}
            </div>
            <div className="row d-flex flex-row my-auto">
              <p className="col-md-5 col-6">Nama Lengkap : </p>{" "}
              <p className="col-md-5 col-6 detail_card">
                {userData?.personalInfo?.name
                  ? userData?.personalInfo?.name
                  : "-"}
              </p>{" "}
            </div>
            <div className="row d-flex flex-row my-auto">
              <p className="col-md-5 col-6">Nomor Telepon : </p>{" "}
              <p className="col-md-5 col-6 detail_card">
                {userData?.personalInfo?.phoneNo
                  ? userData?.personalInfo?.phoneNo
                  : "-"}
              </p>{" "}
            </div>
            <div className="row d-flex flex-row my-auto">
              <p className="col-md-5 col-6">Email : </p>{" "}
              <p className="col-md-5 col-6 detail_card">
                {userData?.personalInfo?.email
                  ? userData?.personalInfo?.email
                  : "-"}
              </p>{" "}
            </div>
            <div className="row d-flex flex-row my-auto">
              <p className="col-md-5 col-6">Tanggal Lahir: </p>{" "}
              <p className="col-md-5 col-6 detail_card">
                {userData?.personalInfo?.birthdate
                  ? userData?.personalInfo?.birthdate
                  : "-"}
              </p>{" "}
            </div>
          </div>
          <div className="mt-1 col-xl-6 col-12  d-flex flex-column gap-2">
            <div className="row d-flex flex-row my-auto">
              <p className="col-md-5 col-6">Tempat Lahir: </p>{" "}
              <p className="col-md-5 col-6 detail_card">
                {userData?.personalInfo?.birthplace
                  ? userData?.personalInfo?.birthplace
                  : "-"}
              </p>{" "}
            </div>

            <div className="row d-flex flex-row my-auto">
              <p className="col-md-5 col-6">Nama Ibu Kandung: </p>{" "}
              <p className="col-md-5 col-6 detail_card">
                {userData?.personalInfo?.mother
                  ? userData?.personalInfo?.mother
                  : "-"}
              </p>{" "}
            </div>
            <div className="row d-flex flex-row my-auto">
              <p className="col-md-5 col-6">Pekerjaan: </p>{" "}
              <p className="col-md-5 col-6 detail_card">
                {userData?.personalInfo?.occupation
                  ? userData?.personalInfo?.occupation
                  : "-"}
              </p>{" "}
            </div>
            <div className="row d-flex flex-row my-auto">
              <p className="col-md-5 col-6">Lokasi Pekerjaan: </p>{" "}
              <p className="col-md-5 col-6 detail_card">
                {userData?.personalInfo?.workLocation
                  ? userData?.personalInfo?.workLocation
                  : "-"}
              </p>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
