import React, { useState } from "react";
import { useRecaptcha } from "react-hook-recaptcha";

const sitekey = "CHANGE_ME_IMPORTANT"; // we need to do
// this:
// https://cloud.google.com/recaptcha-enterprise/docs/getting-started

const containerId = "test-recaptcha"; // this id can be customized

const FormWithRecaptcha = () => {
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const successCallback = (response: any) => {
    setCaptchaResponse(response);
  };
  const expiredCallback = () => setCaptchaResponse(null);

  // useRecaptcha({
  //   containerId,
  //   successCallback,
  //   expiredCallback,
  //   sitekey,
  //   size: "normal",
  // });

  const submitHandler = (e: any) => {
    e.preventDefault();
    const inputNameValue =
      document.querySelector<HTMLInputElement>("#name")?.value;

    alert(
      `Hello ${inputNameValue} \n Recaptcha Response is: ${captchaResponse}`
    );
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="mb-3 d-flex flex-column align-items-start">
        <label htmlFor="name" className="form-label">
          Nama
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          placeholder="Masukkan nama anda"
        />
      </div>
      <div className="mb-3 d-flex flex-column align-items-start">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="text"
          className="form-control"
          id="email"
          placeholder="Masukkan email anda"
        />
      </div>
      <div className="mb-3 d-flex flex-column align-items-start">
        <label htmlFor="email" className="form-label">
          Nomor
        </label>
        <input
          type="number"
          className="form-control"
          id="email"
          placeholder="Masukkan nomor anda"
        />
      </div>
      <div className="mb-3 d-flex flex-column align-items-start">
        <label htmlFor="message" className="form-label">
          Pertanyaan
        </label>
        <textarea
          className="form-control"
          id="message"
          rows={3}
          placeholder="Pesan"
        ></textarea>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <div id={containerId} className="g-recaptcha" />
      </div>

      <button
        className="btn text-white px-5 mt-3"
        style={{
          borderRadius: "10px",
          backgroundColor: "#000000",
          fontSize: ".81em",
        }}
        disabled={!captchaResponse}
        type="submit"
      >
        Kirim
      </button>
    </form>
  );
};

export default FormWithRecaptcha;
