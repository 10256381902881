import React, { useState } from "react";
import Login from "./login/Login";
import { Container, Form, Button } from "react-bootstrap";
import logo from "../../assets/baliolaLoo.png";
import "./Auth.css";
import { useLocation, useParams } from "react-router-dom";
import SignUp from "./signUp/SignUp";
import ForgotPassword from "./forgotPassword/ForgotPassword";
import AccountType from "./accountType/AccountType";
import PackagesOptions from "./packages/PackagesOptions";
import RegistrationMember from "./registration/RegistrationMember";
import PaymentDetail from "./payment/PaymentDetail";
import PaymentProof from "./payment/PaymentProof";
import ConfirmPassword from "./forgotPassword/ConfirmPassword";

export default function Auth() {
  const [name, setName] = useState("");
  const [nik, setNik] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");

  const location = useLocation();
  const { pathname } = location;
  return (
    <div className="bg-image auth_containter">
      <Container
        className="d-flex justify-content-center align-items-center auth_wrapper"
        style={{ minHeight: "100vh" }}
      >
        <div
          className={
            !pathname.includes("packages-option") &&
            !pathname.includes("registration-form")
              ? `card_auth bg-light p-md-5 p-4 rounded`
              : "card_auth_packages bg-light p-md-5 p-4 rounded"
          }
        >
          <div className="header_card_auth d-flex align-items-center justify-content-center">
            <img className="auth_logo" src={logo} alt="" />
            <p className="text-center title_auth d-flex my-auto">
              Perisai Baliola
            </p>
          </div>

          {pathname?.includes("login") && (
            <>
              <p className="mb-2 mt-3 auth_desc">Login</p>
              <p className="auth_desc_text">Selamat Datang Kembali !</p>
            </>
          )}
          {pathname?.includes("sign-up") && (
            <>
              <p className="mb-2 mt-3 auth_desc">Pendaftaran</p>
              <p className="auth_desc_text">Selamat Datang Kembali !</p>
            </>
          )}
          {pathname?.includes("account-type") && (
            <>
              <p className="mb-2 mt-3 auth_desc">Pendaftaran</p>
              <p className="auth_desc_text">Selamat Datang Kembali !</p>
            </>
          )}
          {pathname?.includes("registration-form") && (
            <>
              <p className="mb-2 mt-3 auth_desc">Pendaftaran</p>
              <p className="auth_desc_text">Selamat Datang Kembali !</p>
            </>
          )}
          {pathname?.includes("packages-option") && (
            <>
              <p className="mb-2 mt-3 auth_desc">Pilih Paket</p>
              <p className="auth_desc_text">Selamat Datang!</p>
            </>
          )}
          {pathname?.includes("payment-detail") && (
            <>
              <p className="mb-2 mt-3 auth_desc">Informasi Pembayaran</p>
              {/* <p className="auth_desc_text">Selamat Datang Kembali !</p> */}
            </>
          )}
          {pathname?.includes("payment-proof") && (
            <>
              <p className="mb-2 mt-3 auth_desc">Upload Bukti Pembayaran</p>
              {/* <p className="auth_desc_text">Selamat Datang Kembali !</p> */}
            </>
          )}
          {pathname?.includes("forgot-password") && (
            <>
              <p className="mb-2 mt-3 auth_desc">Lupa Password</p>
              <p className="auth_desc_text">Selamat Datang Kembali !</p>
            </>
          )}
          {pathname?.includes("confirm-password") && (
            <>
              <p className="mb-2 mt-3 auth_desc">Ganti Password</p>
              {/* <p className="auth_desc_text">Selamat Datang Kembali !</p> */}
            </>
          )}
          {pathname?.includes("forgot-password") && <ForgotPassword />}
          {pathname?.includes("confirm-password") && <ConfirmPassword />}

          {pathname?.includes("login") && <Login />}
          {pathname?.includes("sign-up") && <SignUp />}
          {pathname?.includes("account-type") && <AccountType />}
          {pathname?.includes("packages-option") && <PackagesOptions />}
          {pathname?.includes("registration-form") && (
            <RegistrationMember
              name={name}
              nik={nik}
              email={email}
              phone={phoneNo}
            />
          )}
          {pathname?.includes("payment-detail") && <PaymentDetail />}
          {pathname?.includes("payment-proof") && <PaymentProof />}
        </div>
      </Container>
    </div>
  );
}
