import { motion, useAnimate, useInView } from "framer-motion";
import "./VerticalTimeline.css";
import { Link } from "react-router-dom";
const vertTimelineData = [
  {
    index: 1,
    title: "Daftar Akun Perisai Baliola",
    content: "Daftar akun Perisai Baliola untuk memulai pengalaman Anda.",
  },
  {
    index: 2,
    title: "Pastikan akun perisai anda aktif",
    content:
      "Pastikan akun Perisai Anda aktif untuk dapat mengakses semua fitur dan layanan.",
  },
  {
    index: 3,
    title: "Login Akun PERISAI",
    content:
      "Login ke akun PERISAI Anda untuk mengelola profil dan akses informasi terkait.",
  },
  {
    index: 4,
    title: "KLAIM",
    content:
      "Ajukan klaim untuk memperoleh manfaat yang disediakan oleh Perisai Baliola.",
  },
];

interface vertTimelineDataProps {
  index: number;
  title: string;
  content: string;
}
// the individual cards
const VertTimelineCard = ({ data }: { data: vertTimelineDataProps }) => {
  const [scope, animate] = useAnimate();
  const isinView = useInView(scope, { once: true });
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={
        isinView
          ? { opacity: 1, transition: { delay: 0.5 + data.index * 0.25 } }
          : { opacity: 0 }
      }
      transition={{ duration: 1 }}
      ref={scope}
      className="timeline-card"
    >
      <div className="timeline-card-content">
        <div className="number-section">
          <span className="number">{data.index}</span>
        </div>
        <div>
          <h3 className="title">{data.title}</h3>
          <p className="description">{data.content}</p>
          <span className="circle" />
        </div>
      </div>
    </motion.div>
  );
};

// the timeline
const VerticalTimeline = () => {
  const [scope, animate] = useAnimate();
  const isinView = useInView(scope, { once: true });
  return (
    <div>
      {/* just a header */}
      <h1
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "5rem",
        }}
      >
        Alur Klaim
      </h1>
      {/* the timeline */}
      <div className="timeline-container">
        {vertTimelineData.map((data, idx) => {
          return <VertTimelineCard data={data} key={idx} />;
        })}
      </div>
      {/* claim button */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={
          isinView
            ? { opacity: 1, transition: { delay: 1.75 } }
            : { opacity: 0 }
        }
        transition={{ duration: 1 }}
        ref={scope}
        className="d-flex justify-content-center"
      >
        <button
          style={{
            background: "#050038",
            borderRadius: "49px",
            color: "white",
            alignItems: "center",
            padding: "1rem 2rem",
            fontWeight: "bold",
          }}
        >
          {/* router link to /auth/login */}
          <Link
            to="/auth/login"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            KLAIM BPJS ANDA SEKARANG
          </Link>
        </button>
      </motion.div>
    </div>
  );
};

export default VerticalTimeline;
