import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AuthAPI } from "../../../services/auth/Auth";
import { defaultSignUp, SignUpModel } from "./SignUpModel";
import axios from "axios";
import * as auth from "../../../utils/redux/AuthCRUD";
import { toast, ToastContainer } from "react-toastify";

type SignUpProp = {
  setName: (name: string) => void;
  setNik: (nik: string) => void;
  phoneNo: (phone: string) => void;
  email: (email: string) => void;
};

// MEMBER ACCOUNT REGISTRATION
export default function SignUp() {
  const navigation = useNavigate();
  const [validated, setValidated] = useState(false);
  const [validatedNik, setValidatedNik] = useState(false);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<SignUpModel>(defaultSignUp);

  const updateData = (fieldsToUpdate: Partial<SignUpModel>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  // console log
  console.log("validation", validated);
  console.log("validation nik", data.signUpData.nik.length);
  // end log

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log("login Data", data);
    event.preventDefault();
    const form = event.currentTarget;
    console.log("event", event);

    //Form Validation
    if (
      form.checkValidity() === false ||
      !data.signUpData.email ||
      !data.signUpData.name ||
      !data.signUpData.nik ||
      !data.signUpData.password ||
      !data.signUpData.phoneNo
    ) {
      console.log("checking form");
      event.preventDefault();
      event.stopPropagation();
      toast.error("Mohon isi form dengan lengkap");
    }
    setValidatedNik(true);
    setValidated(true);
    console.log("Form submitted successfully");
    console.log("login Data", data);
    await signUp();
  };

  async function signUp() {
    setLoading(true);
    const signUpData = {
      nik: data.signUpData.nik,
      password: data.signUpData.password,
      email: data.signUpData.email,
      name: data.signUpData.name,
      phoneNo: data.signUpData.phoneNo,
    };
    // Hit SIGN UP API
    await AuthAPI.SignUpAPI(signUpData)
      .then(async (response) => {
        console.log("response", response.data);

        // hit login api
        const LoginData = {
          nik: data.signUpData.nik,
          password: data.signUpData.password,
        };
        await AuthAPI.LoginApi(LoginData)

          .then(async (loginResponse) => {
            setLoading(false);
            const token = loginResponse?.data?.data?.accessToken;
            await auth.setAuthLocal(token);
            toast.success("Selamat and berhasil melakukan registrasi akun");
            setTimeout(() => {
              navigation("/auth/packages-option");
            }, 3000);
          })
          .catch((error: any) => {
            setLoading(false);
            console.log("error", error.response);
            const { message } = error.response.data;

            // Handle API error
            if (axios.isAxiosError(error) && error.response) {
              setLoading(false);
              console.error("API error:", message);
              toast.error(message);
            } else {
              setLoading(false);
              console.error("Other error:", error);
              toast.error(message);
            }
          });
        // End hit login Api
      })
      .catch((error: any) => {
        setLoading(false);
        console.log("error", error.response);
        const { message } = error.response.data;

        // Handle API error
        if (axios.isAxiosError(error) && error.response) {
          console.error("API error:", message);
          toast.error(message);
        } else {
          console.error("Other error:", error);
          toast.error(message);
        }
      });
    // END HIT LOGIN SIGN-UP
  }
  return (
    <>
      <Form validated={validated} onSubmit={handleSubmit} noValidate>
        <Form.Group controlId="formBasicNIK">
          <p className="input_label">NIK Sesuai KTP</p>
          <Form.Control
            className="form-control input_field_nik"
            type="number"
            placeholder="Masukan NIK anda 16 Digit"
            value={data.signUpData.nik}
            name="nik"
            onChange={async (e) => {
              updateData({
                signUpData: {
                  nik: e.target.value,
                  name: data.signUpData.name,
                  email: data.signUpData.email,
                  phoneNo: data.signUpData.phoneNo,
                  password: data.signUpData.password,
                },
              });
            }}
          />
          <Form.Control.Feedback type="invalid">
            {!validatedNik
              ? "Panjang NIK Harus 16 digit angka"
              : " Mohon masukan NIK anda"}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mt-3" controlId="formBasicEmail">
          <p className="input_label">Email</p>
          <Form.Control
            className="form-control input_field_email"
            type="email"
            placeholder="Contoh: michaeljordan@gmail.com"
            value={data.signUpData.email}
            onChange={async (e) => {
              updateData({
                signUpData: {
                  nik: data.signUpData.nik,
                  name: data.signUpData.name,
                  email: e.target.value,
                  phoneNo: data.signUpData.phoneNo,
                  password: data.signUpData.password,
                },
              });
            }}
          />
          <Form.Control.Feedback type="invalid">
            Mohon masukan email anda
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mt-3" controlId="formBasicName">
          <p className="input_label">Nama Lengkap</p>
          <Form.Control
            className="form-control input_field_name"
            type="text"
            placeholder="contoh: Michael Jordan"
            value={data.signUpData.name}
            onChange={async (e) => {
              updateData({
                signUpData: {
                  nik: data.signUpData.nik,
                  name: e.target.value,
                  email: data.signUpData.email,
                  phoneNo: data.signUpData.phoneNo,
                  password: data.signUpData.password,
                },
              });
            }}
          />
          <Form.Control.Feedback type="invalid">
            Mohon masukan nama lengkap anda
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mt-3" controlId="formBasicPhone">
          <p className="input_label">Nomor Whatsapp Aktif</p>
          <Form.Control
            className="form-control input_field_phone"
            type="number"
            placeholder="Contoh: 08212312849213"
            value={data.signUpData.phoneNo}
            onChange={async (e) => {
              updateData({
                signUpData: {
                  nik: data.signUpData.nik,
                  name: data.signUpData.name,
                  email: data.signUpData.email,
                  phoneNo: e.target.value,
                  password: data.signUpData.password,
                },
              });
            }}
          />
          <Form.Control.Feedback type="invalid">
            Mohon masukan nomor Whatsapp anda
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mt-3" controlId="formBasicPassword">
          <p className="input_label">Password</p>
          <Form.Control
            className="form-control input_field_pass"
            type="password"
            placeholder="Masukan Password anda"
            value={data.signUpData.password}
            onChange={async (e) => {
              updateData({
                signUpData: {
                  nik: data.signUpData.nik,
                  name: data.signUpData.name,
                  email: data.signUpData.email,
                  phoneNo: data.signUpData.phoneNo,
                  password: e.target.value,
                },
              });
            }}
          />
          <Form.Control.Feedback type="invalid">
            Mohon masukan password anda
          </Form.Control.Feedback>
        </Form.Group>
        <a className="forgot-pass mt-3" href="/auth/login">
          Sudah memiliki akun ?
        </a>
        {!loading ? (
          <Button
            variant="primary"
            type="submit"
            // onClick={onNext}
            className="btn_login w-100 mt-3 border-0"
            style={{
              background: "#142657",
              borderRadius: "10px",
              height: "49px",
            }}
          >
            Sign up
          </Button>
        ) : (
          <Button
            type="submit"
            className="btn_login w-100 mt-3 border-0"
            // onClick={onLogin}
            style={{
              background: "#142657",
              borderRadius: "10px",
              height: "49px",
            }}
            disabled
          >
            Loading...
          </Button>
        )}
      </Form>
      <ToastContainer />
    </>
  );
}
