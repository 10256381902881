import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Member } from "../../../../services/registration/Member";
import CheckMark from "../../../../assets/icons/checkMark2.png";

export default function RegistrationModal(props: any) {
  const [loading, setLoading] = useState(false);
  const { dataRegistration } = props;

  const navigator = useNavigate();

  async function handleSubmit() {
    setLoading(true); // LOADING
    const program = dataRegistration?.package;
    const programCode = program.replace("/auth/registration-form/", "");
    const memberRegistrationData = {
      programCode,
      biologicalMotherName: dataRegistration?.motherName,
      birthdate: dataRegistration?.dateOfBirth,
      birthplace: dataRegistration?.placeOfBirth,
      occupationId: dataRegistration?.job,
      workLocationId: dataRegistration?.jobLocation,
    };
    console.log("uploading personal data...");

    // HIT API BPJS MEMBER REGISTRATION
    await Member.MemberRegistration(memberRegistrationData)
      .then(async (registerResponse) => {
        // GET DATA FROM API RESPONSE
        const registrationId = registerResponse?.data?.data.bpjsRegistrationId;
        const paymentId = registerResponse?.data?.data.paymentId;
        // END GET DATA FROM API RESPONSE

        const photoKTP = dataRegistration?.file;

        const kind = "card"; // TYPE OF IMAGE UPLOAD

        // FORM DATA
        const newUploadDataKTP = new FormData();
        newUploadDataKTP.append("ktp", photoKTP);
        newUploadDataKTP.append("kind", kind);
        console.log("uploading foto ktp.....");

        // UPLOAD KTP PHOTO
        await Member.UploadKTPAPI(newUploadDataKTP, registrationId)
          .then(async (response) => {
            const kindSelfie = "selfie";
            const PhotoKTPSelfie = dataRegistration?.selfie;
            const newUploadDataKTPSelfue = new FormData();
            newUploadDataKTPSelfue.append("ktp", PhotoKTPSelfie);
            newUploadDataKTPSelfue.append("kind", kindSelfie);
            console.log("uploading foto selfie dengan ktp.....");

            // UPLOAD SELFIE WITH KTP IMAGE
            await Member.UploadKTPAPI(newUploadDataKTPSelfue, registrationId)
              .then(() => {
                setLoading(false);
                toast.success(
                  "Selamat and berhasil melakukan pendaftaran kartu BPJS"
                );
                setTimeout(() => {
                  navigator(`/auth/payment-detail/${paymentId}`);
                }, 3000);
              })
              // ERROR HANDLING FOR UPLOAD SELFIE WITH KTP
              .catch((error: any) => {
                setLoading(false);
                console.log("error upload selfie");
                console.log("error response", error.response.data.message);
                console.log("error msg", error.response);
                console.log("error ", error);
                const { message } = error.response.data.message;
                // Handle API error
                if (axios.isAxiosError(error) && error.response) {
                  setLoading(false);
                  console.error("API error:", message);
                  toast.error(message);
                } else {
                  setLoading(false);
                  console.error("Other error:", error);
                  toast.error(message);
                }
              });
          })

          // ERROR HANDLING FOR UPLOAD KTP PHOTO
          .catch((error: any) => {
            setLoading(false);
            console.log("error upload foto ktp");
            console.log("error response", error.response.data.message);
            console.log("error msg", error.response);
            console.log("error ", error);
            const { message } = error;
            // Handle API error
            if (axios.isAxiosError(error) && error.response) {
              setLoading(false);
              console.error("API error:", message);
              toast.error(message);
            } else {
              setLoading(false);
              console.error("Other error:", error);
              toast.error(message);
            }
          });
      })

      // ERROR HANDLING FOR BPJS MEMBER REGISTRATION
      .catch((error: any) => {
        console.log("error uploading registrant personal data");
        setLoading(false);
        console.log("error response", error.response);
        // console.log("error msg", error.response.message);
        console.log("error ", error);
        const { message } = error;
        // Handle API error
        if (axios.isAxiosError(error) && error.response) {
          setLoading(false);
          console.error("API error:", message);
          toast.error(message);
        } else {
          setLoading(false);
          console.error("Other error:", error);
          toast.error(message);
        }
      });
    // navigator("/auth/payment-detail");
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header> */}
      {/* <Modal.Title id="contained-modal-title-vcenter">
          Konfirmasi Data Diri
        </Modal.Title> */}
      {/* </Modal.Header> */}
      <Modal.Body className="d-flex flex-column text-center ustify-content-center align-items-center p-5">
        <img
          src={CheckMark}
          alt=""
          style={{ maxWidth: "60px" }}
          className="mb-2"
        />
        <h4>
          Konfirmasi Data Diri
          {/* Apakah Anda sudah mengisi semua informasi yang dibutuhkan dalam
          formulir pendaftaran dengan benar? */}
        </h4>{" "}
        <p className="forgot-pass">
          Apakah Anda sudah mengisi semua informasi yang dibutuhkan dalam
          formulir pendaftaran dengan benar?
        </p>{" "}
        <div className="d-flex flex-sm-row flex-column gap-2 mt-3">
          {" "}
          <Button
            variant="primary bg-transparent border-2"
            onClick={() => props.setShow(false)}
            style={{
              // background: "#54B435",
              color: "#142657",
              borderColor: "#142657",
              borderRadius: "10px",
              height: "49px",
              width: "150px",
            }}
          >
            Kembali
          </Button>
          {loading === true ? (
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit}
              style={{
                background: "#142657",
                borderRadius: "10px",
                height: "49px",
                border: "0px",
                width: "150px",
              }}
              disabled
            >
              Mengirim...{" "}
            </Button>
          ) : (
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit}
              style={{
                background: "#142657",
                borderRadius: "10px",
                height: "49px",
                border: "0px",
                width: "150px",
              }}
            >
              Kirim{" "}
            </Button>
          )}
        </div>
      </Modal.Body>

      {/* <Modal.Footer>
        <Button
          variant="primary bg-transparent border-2"
          onClick={() => props.setShow(false)}
          style={{
            // background: "#54B435",
            color: "#142657",
            borderColor: "#142657",
            borderRadius: "10px",
            height: "49px",
          }}
        >
          Kembali
        </Button>
        {loading === true ? (
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
            style={{
              background: "#142657",
              borderRadius: "10px",
              height: "49px",
              border: "0px",
            }}
            disabled
          >
            Mengirim...{" "}
          </Button>
        ) : (
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
            style={{
              background: "#142657",
              borderRadius: "10px",
              height: "49px",
              border: "0px",
            }}
          >
            Kirim{" "}
          </Button>
        )}
      </Modal.Footer> */}
    </Modal>
  );
}
