import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Member } from "../../../services/registration/Member";
import {
  MembershipCard,
  PaymentDetail,
  PersonalInfo,
} from "../../../utils/models/UserModel";
import DashboardLayout from "../../common/layout/DashboardLayout";
import NewAccountPage from "./Account/NewAccountPage";
import Payment from "./payment/Payment";

export interface Data {
  personalInfo: PersonalInfo;
  registrationStatus: string;
  unpaidPayment: PaymentDetail;
}
export interface CardData {
  membershipCard: MembershipCard | null;
}

export default function Dashboard() {
  // const [isHover, setIsHover] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const dataFetchRef = useRef(false);
  const [carData, setCardData] = useState<CardData>({ membershipCard: null });
  const [data, setData] = useState<Data>({
    personalInfo: {
      nik: "",
      name: "",
      phoneNo: "",
      email: "",
      birthdate: "",
      birthplace: "",
      mother: "",
      workLocation: "",
      occupation: "",
    },
    registrationStatus: "",
    unpaidPayment: {
      paymentId: 0,
    },
  });

  async function GetUserInformation() {
    console.log("getting member data");

    await Member.MemberInfoApi()
      .then((response) => {
        const userData = response.data.data;
        setData(userData);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }

  async function GetCardInformation() {
    console.log("getting card data");
    await Member.InfoCardApi()
      .then((response) => {
        console.log("card information", response?.data?.data);
        const membershipCardData = response.data.data;
        setCardData(membershipCardData);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }

  useEffect(() => {
    console.log("use effect running....");
    // if (dataFetchRef.current) return;
    // dataFetchRef.current = true;
    GetUserInformation();
    GetCardInformation();
  }, []);

  return (
    <>
      <DashboardLayout title="">
        {pathname.includes("dashboard") && (
          <NewAccountPage userData={data} cardData={carData} />
        )}
        {pathname.includes("pembayaran") && <Payment />}
      </DashboardLayout>
    </>
  );
}
