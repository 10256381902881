import axios from "axios";
import { config } from "../../../config/config";
import { GetToken } from "../../utils/redux/AuthCRUD";

export const API_URL =
  config.SERVER_ENV === "staging"
    ? config.API_URL_STAGING
    : config.API_URL_PROD;
const GET_WORK_LOCATION_URL = `${API_URL}/work-locations`;
const GET_OCCUPATION_URL = `${API_URL}/occupations`;

export class Occpations {
  public static async GetWorkLocationAPI() {
    return axios.get(GET_WORK_LOCATION_URL, {
      headers: {
        Authorization: `Bearer ${GetToken()}`,
      },
    });
  }
  public static async GetOccupationsAPI() {
    return axios.get(GET_OCCUPATION_URL, {
      headers: {
        Authorization: `Bearer ${GetToken()}`,
      },
    });
  }
}
