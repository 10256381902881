import React, { useEffect, useRef, useState } from "react";
import ThreePrograms from "../../assets/tiga-program.png";
import TwoPrograms from "../../assets/dua-program.png";
import "../../containers/registrationBPJS/packages/Packages.css";
import { Programs } from "../../services/registration/packages";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { PackagesType } from "./PackagesType";
import { Link } from "react-router-dom";

export default function PackagesCard() {
  const [programsData, setProgramsData] = useState<PackagesType[] | null>(null);
  const dataFetchedRef = useRef(false);

  console.log("displaying programs...", programsData);

  async function displayPackages() {
    console.log("displaying programs...");
    await Programs.GetProgramsAPI()
      .then((programs) => {
        const listPackages = programs.data.data.programs;
        setProgramsData(listPackages);
      })
      .catch((error: any) => {
        // setLoading(false);
        console.log("error", error);
        const { message } = error;

        // Handle API error
        if (axios.isAxiosError(error) && error) {
          // setLoading(false);
          console.error("API error:", message);
          toast.error(message);
        } else {
          console.error("Other error:", error);
          toast.error(message);
        }
      });
  }
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    displayPackages();
  }, []);
  console.log("list packages", programsData);
  return (
    <div className="card_container d-flex flex-column flex-md-row gap-3">
      {programsData &&
        programsData.map((program, index) => (
          <div
            className={`col-md-6 col-12 ${
              program.programCode === "bpu-3-program" ? "" : "mt-5 mt-md-0"
            }`}
            key={index}
          >
            <div
              className="card packages_card"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <h5 className="card-header packages_card_header text-center">
                {program.title}
              </h5>
              <div className="card-body d-flex flex-column justify-content-between">
                <section>
                  <img
                    src={
                      program.programCode === "bpu-3-program"
                        ? ThreePrograms
                        : TwoPrograms
                    }
                    className="card-img-top"
                    alt="..."
                  />
                  <p className="card-text packages_card_text">
                    {program.description}
                  </p>
                  <ol className="packages_card_text">
                    {program.benefits.map((benefit, index) => (
                      <li key={index}>{benefit}</li>
                    ))}
                  </ol>
                </section>
                {/* <p
                  className={`text-center price ${
                    program.programCode === "bpu-3-program" ? "mt-md-5" : "mt-2"
                  }`}
                >
                  {program.pricePerPax}
                </p> */}
                <Link
                  to={`/auth/registration-form/${program.programCode}`}
                  className={`btn w-100 btn_register_bpjs ${
                    program.programCode === "bpu-3-program" && "mt-md-4"
                  } ${program.programCode === "bpu-2-program" && "mt-md-0"} `}
                >
                  Daftar
                </Link>
              </div>
            </div>
          </div>
        ))}{" "}
      {/* <div className="col-md-6 col-12">
        <div className="card packages_card">
          <h5 className="card-header packages_card_header text-center">
            Bukan Penerima Upah 3 Program
          </h5>
          <div className="card-body">
            <img src={ThreePrograms} className="card-img-top" alt="..." />
            <p className="card-text packages_card_text">
              Direkomendasikan untuk wirausaha, freelancer, pekerja paruh waktu
              ataupun pekerja lainnya tanpa ikatan kerja.
            </p>
            <ol className="packages_card_text">
              <li>Jaminan Kecelakaan Kerja</li>
              <li>Jaminan Kematian</li>
              <li>Jaminan Hari Tua</li>
            </ol>
            <p className="text-center price">Rp. 36.800,-/orang</p>
            <a
              href="/auth/registration-form/bpu-3-program"
              className="btn w-100 btn_register_bpjs"
            >
              Daftar
            </a>
          </div>
        </div>
      </div> */}
      {/* <div className="col-md-6 col-12 mt-5 mt-md-0">
        <div className="card packages_card">
          <h5 className="card-header packages_card_header text-center">
            Bukan Penerima Upah 2 Program
          </h5>
          <div className="card-body">
            <img src={TwoPrograms} className="card-img-top" alt="..." />
            <p className="card-text packages_card_text">
              Direkomendasikan untuk wirausaha, freelancer, pekerja paruh waktu
              ataupun pekerja lainnya tanpa ikatan kerja.
            </p>
            <ol className="packages_card_text">
              <li>Jaminan Kecelakaan Kerja</li>
              <li>Jaminan Kematian</li>
            </ol>
            <p className="text-center price mt-md-5">Rp. 16.800,-/orang</p>
            <a
              href="/auth/registration-form/bpu-2-program"
              className="btn w-100 btn_register_bpjs"
            >
              Daftar
            </a>
          </div>
        </div>
      </div>{" "} */}
      <ToastContainer />
    </div>
  );
}
