import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import c1 from "./assets/Carousel/c1.svg";
import c2 from "./assets/Carousel/c2.svg";
import BadanKreatifDenpasarIcon from "./assets/PartnerIcons/badankreatifdenpasar.svg";
import GEKRAFSICON from "./assets/PartnerIcons/gekrafs.svg";
import BaliBlockchainCenterIcon from "./assets/PartnerIcons/baliblockchaincenter.svg";

const data = [
  {
    id: 0,
    title: "PELINDUNG PEKERJA KREATIF",
    imagesrc: c1,
    description:
      "Berikan perlindungan terbaik bagi pekerja kreatif seperti seniman dan content writer. Daftar BPJS Perisai sekarang, menjaga karya dan masa depanmu!",
  },
  {
    id: 1,
    title: "MILLENIALS FREELANCE",
    imagesrc: c2,
    description:
      "Jaga masa depanmu, daftarlah BPJS Perisai! Lindungi dirimu sebagai millennial dan pekerja freelance, baik dalam pekerjaanmu maupun saat memasuki masa tua.",
  },
  {
    id: 2,
    title: "SUPPORTED BY",
    imagesrc: undefined,
    description:
      "BPJS Perisai Baliola didukung oleh Badan Kreatif Kota Denpasar, Gerakan Ekonomi Kreatif Nasional Bali, dan Bali Blockchain Center. Bergabunglah dengan kami dan nikmati perlindungan terbaik.",
  },
];

const Carousel = () => {
  return (
    <div
      className="container d-flex align-items-center justify-content-center mt-5 mt-sm-4"
      style={{
        width: "100%",
        padding: "0",
      }}
    >
      <Swiper
        slidesPerView={1}
        loop={true}
        spaceBetween={32}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        modules={[FreeMode, Autoplay, Pagination, Navigation]}
        style={{
          padding: "16px",
        }}
      >
        {data.map((item) => (
          <SwiperSlide
            key={item.id}
            className="d-flex justify-content-center align-items-center"
            style={{
              height: "auto",
              // border with shadow
              boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
              padding: "16px",
            }}
          >
            {/* first and second slide */}
            {item.imagesrc ? (
              <div
                className="row g-0 mb-5 bg-body rounded"
                style={{
                  maxWidth: "1216px",
                }}
              >
                <div className="col-md-6 col-12 p-4">
                  <img
                    src={item.imagesrc}
                    className="img-fluid rounded-start"
                    alt="..."
                  />
                </div>
                <div className="col-md-6 col-12">
                  <div className="card-body">
                    <h5
                      className="card-title"
                      style={{
                        fontWeight: "bold",
                        fontSize: "3rem",
                        textAlign: "left",
                      }}
                    >
                      {item.title}
                    </h5>
                    <p
                      className="card-text"
                      style={{
                        textAlign: "left",
                        fontSize: "1.2rem",
                      }}
                    >
                      {item.description}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              // the third slide
              <div
                className="p-4 mb-5 bg-body w-100"
                style={{
                  width: "20vw",
                  maxWidth: "1216px",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h5
                    className="card-title"
                    style={{
                      fontWeight: "bold",
                      fontSize: "3rem",
                      textAlign: "center",
                    }}
                  >
                    {item.title}
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      marginBottom: "3rem",
                      maxWidth: "885px",
                    }}
                  >
                    {item.description}
                  </p>
                  {/* three images in a row */}
                  <div className="container">
                    <div className="row align-items-center justify-content-center">
                      <img
                        src={BadanKreatifDenpasarIcon}
                        className="col img-fluid rounded-start"
                        alt="..."
                        style={{
                          maxWidth: "320px",
                          width: "30%",
                          margin: "0 3rem",
                        }}
                      />
                      <img
                        src={GEKRAFSICON}
                        className="col img-fluid rounded-start"
                        alt="..."
                        style={{
                          maxWidth: "320px",
                          width: "30%",
                          margin: "0 3rem",
                        }}
                      />
                      <img
                        src={BaliBlockchainCenterIcon}
                        className="col img-fluid rounded-start"
                        alt="..."
                        style={{
                          maxWidth: "320px",
                          width: "30%",
                          margin: "0 3rem",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
