import React, { useState } from "react";
import { Pagination, Table } from "react-bootstrap";

import "./Payment.css";

type PaymentHistoryType = {
  index: number;
  harga: number;
  tanggal: string;
  masaBerlaku: number;
  status: string;
};

const myObjects: PaymentHistoryType[] = [
  {
    index: 1,
    harga: 100,
    tanggal: "2023-05-06",
    masaBerlaku: 365,
    status: "Pending",
  },
  {
    index: 2,
    harga: 50,
    tanggal: "2023-04-01",
    masaBerlaku: 180,
    status: "Success",
  },
  {
    index: 3,
    harga: 200,
    tanggal: "2023-06-15",
    masaBerlaku: 90,
    status: "Failed",
  },
  {
    index: 4,
    harga: 75,
    tanggal: "2023-05-20",
    masaBerlaku: 30,
    status: "Success",
  },
  {
    index: 5,
    harga: 150,
    tanggal: "2023-07-01",
    masaBerlaku: 365,
    status: "Pending",
  },
];

export default function Payment() {
  const [activeFilter, setActiveFilter] = useState("Semua");

  const handleFilterClick = (filterName: string) => {
    setActiveFilter(filterName);
  };
  return (
    <div className="container_fluid">
      <p className="fs-5 fw-bolder"> Riwayat Pembayaran </p>
      <div className="table_container mt-3">
        <div className="table_filter">
          <nav className="nav nav-pills flex-row flex-sm-row gap-4">
            <a
              className={`flex-sm-fill text-sm-center nav-link table_filter_option ${
                activeFilter === "Semua" ? "active" : ""
              }`}
              aria-current="page"
              href="#"
              onClick={() => handleFilterClick("Semua")}
            >
              Semua
            </a>
            <a
              className={`flex-sm-fill text-sm-center nav-link table_filter_option ${
                activeFilter === "Success" ? "active" : ""
              }`}
              href="#"
              onClick={() => handleFilterClick("Success")}
            >
              Success
            </a>
            <a
              className={`flex-sm-fill text-sm-center nav-link table_filter_option ${
                activeFilter === "Pending" ? "active" : ""
              }`}
              href="#"
              onClick={() => handleFilterClick("Pending")}
            >
              Pending
            </a>
            <a
              className={`flex-sm-fill text-sm-center nav-link table_filter_option ${
                activeFilter === "Failed" ? "active" : ""
              }`}
              href="#"
              onClick={() => handleFilterClick("Failed")}
            >
              Failed
            </a>
          </nav>
        </div>
        <div className="card_table_container mt-2 mt-sm-5">
          <Table responsive className="">
            <thead className="table_header">
              <tr>
                <th className="table_title">No</th>
                <th className="table_title">Harga</th>
                <th className="table_title">Tanggal</th>
                {/* <th className="table_title">Masa Berlaku</th> */}
                <th className="table_title">Status</th>
              </tr>
            </thead>
            <tbody>
              {myObjects.map((object) => (
                <tr key={object.index}>
                  <td className="table_content">{object.index}</td>
                  <td className="table_content">{object.harga}</td>
                  <td className="table_content">{object.tanggal}</td>
                  {/* <td className="table_content">{object.masaBerlaku}</td> */}
                  <td className="table_content">
                    {object.status === "Success" && (
                      <p className="status_payment_success text-center">
                        {object.status}
                      </p>
                    )}
                    {object.status === "Pending" && (
                      <p className="status_payment_pending text-center">
                        {object.status}
                      </p>
                    )}
                    {object.status === "Failed" && (
                      <p className="status_payment_failed text-center">
                        {object.status}
                      </p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="table_pagination d-flex justify-content-end mt-3">
          <Pagination>
            <Pagination.First />
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Ellipsis />
            <Pagination.Item>{20}</Pagination.Item>
            <Pagination.Next />
            <Pagination.Last />
          </Pagination>
        </div>
      </div>
    </div>
  );
}
