import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import PhotoKTP from "../../../assets/KTPPhotoExample.jpeg";
import PhotoKTPSelfie from "../../../assets/FOTOSelfieKTP.jpeg";

import CloudBrowse from "../../../assets/cloudBrowse.png";

import "./Registration.css";
import RegistrationModal from "../modal/registrationModal/RegistrationModal";
import { WorkList, WorkLocation } from "../../../utils/models/WorkType";
import { Occpations } from "../../../services/registration/Occupation";
import { calculateAge } from "../../../utils/registration/AgeCalculation";
import { Member } from "../../../services/registration/Member";

type RegistrationProps = {
  name: string;
  nik: string;
  email: string;
  phone: string;
};

export default function RegistrationForm(props: RegistrationProps) {
  const location = useLocation();
  const { pathname } = location;
  const navigator = useNavigate();
  const dataFetchedRef = useRef(false);

  const { name, nik, email, phone } = props;

  const [validated, setValidated] = useState(false);
  const [isAgeValid, setIsAgeValid] = useState(true);

  const [search, setSearch] = useState("");
  const [value, setValue] = useState("");

  const [workLocation, setWorkLocation] = useState<WorkLocation[] | null>(null);
  const [occupation, setOccupation] = useState<WorkList[] | null>(null);

  // const [filteredOptions, setFilteredOptions] = useState(workLocation); // Assuming you have a state variable 'workLocation' containing the array of options
  // const [filteredOptions, setFilteredOptions] = useState<WorkLocation[]>([]);

  const [valueJob, setValueJob] = useState("");
  const [searchJob, setSearchJob] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [previewUrlSelfie, setPreviewUrlSelfie] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    name: name,
    nik: nik,
    email: email,
    placeOfBirth: "",
    dateOfBirth: "",
    motherName: "",
    phone: phone,
    job: "",
    jobLocation: "",
    file: {} as File,
    selfie: {} as File,
    package: pathname,
  });

  // --------- HANDLE CHANGED ---------
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name !== "job" && name !== "jobLocation") {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const allowedExtensions = ["png", "jpg", "jpeg"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
      const isExtensionAllowed = allowedExtensions.includes(fileExtension);

      if (!isExtensionAllowed) {
        toast.error("Hanya file PNG, JPG, dan JPEG yang diperbolehkan");
        setFormData({ ...formData, file: {} as File });
        setPreviewUrl(CloudBrowse);
      } else if (file.size > 2 * 1024 * 1024) {
        toast.error("Ukuran file harus dibawah 2 MB");
        setFormData({ ...formData, file: {} as File });
        setPreviewUrl(CloudBrowse);
      } else {
        setFormData({ ...formData, file: file });
        const previewUrl = URL.createObjectURL(file);
        setPreviewUrl(previewUrl);
      }
    }
  };

  const handleFileInputChangeSelfie = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const allowedExtensions = ["png", "jpg", "jpeg"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
      const isExtensionAllowed = allowedExtensions.includes(fileExtension);

      if (!isExtensionAllowed) {
        toast.error("Hanya file PNG, JPG, dan JPEG yang diperbolehkan");
        setFormData({ ...formData, selfie: {} as File });
        setPreviewUrlSelfie(CloudBrowse);
      } else if (file.size > 2 * 1024 * 1024) {
        toast.error("Ukuran file harus dibawah 2 MB");
        setFormData({ ...formData, selfie: {} as File });
        setPreviewUrlSelfie(previewUrl);
      } else {
        setFormData({ ...formData, selfie: file });
        const previewUrl = URL.createObjectURL(file);
        setPreviewUrlSelfie(previewUrl);
      }
    }
  };
  // --------- HANDLE CHANGED ---------

  // --------- ONCLICK FUNCTION ---------

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const age = calculateAge(formData.dateOfBirth);
    console.log("age", age);

    // FORM VALIDATION
    if (form.checkValidity() === false) {
      event.preventDefault();
      toast.error("Mohon isi form dengan lengkap");
      console.log("file size", formData.file);

      // CHECKING NIK LENGTH
    } else if (formData.nik.length !== 16) {
      console.log("checking nik");
      toast.error("Pastikan format NIK benar (16 digit angka)");
      return;

      // AGE VALIDATION
    } else if (age > 65) {
      console.log("you are too old !");
      toast.error("Umur maksimal 65 tahun");
    } else if (age < 0) {
      toast.error("Mohon masukan tanggal lahir secara benar");
    } else {
      setValidated(true);
      setModalShow(true);
      setModalShow(true);
      // navigator("/auth/payment-detail");
    }
  };

  // NAVIGATION FUNCTION (BACK PAGE FUNCTION)
  function onBack() {
    navigator("/auth/packages-option");
  }
  // --------- END ONCLICK FUNCTION ---------

  // --------- FILTER FUNCTION ---------

  const filteredOptions = workLocation?.filter((city) =>
    city.location.toLowerCase().includes(search.toLowerCase())
  );

  const filteredJob = occupation?.filter((job) =>
    job.occupation.toLowerCase().includes(searchJob.toLowerCase())
  );
  const handleSelect = (selectedValue: any) => {
    setValue(selectedValue?.location);
    setFormData((prevFormData) => ({
      ...prevFormData,
      jobLocation: selectedValue?.id,
    }));
    setSearch("");
  };
  const handleSelectJob = (selectedValue: any) => {
    setValueJob(selectedValue?.occupation);
    setFormData((prevFormData) => ({
      ...prevFormData,
      job: selectedValue?.id,
    }));
    setSearchJob("");
  };
  // --------- END FILTER FUNCTION ---------

  // --------- GET WORK LOCATION LIST ---------

  async function getWorkLocations() {
    await Occpations.GetWorkLocationAPI().then((response) => {
      // console.log("WORK LOCATIONS", response.data.data.workLocations);
      const listWorkLocations = response.data.data.workLocations;
      setWorkLocation(listWorkLocations);
    });
  }

  // --------- GET WORK LOCATION LIST ---------

  // --------- GET Occupation LIST ---------
  async function getOccupations() {
    await Occpations.GetOccupationsAPI().then((response) => {
      // console.log("OCCUPATIONS", response.data.data.occupations);
      const listOccupations = response.data.data.occupations;
      setOccupation(listOccupations);
    });
  }

  // --------- GET Occupation LIST ---------

  // --------- GET MEMBER DATA ---------

  async function getMember() {
    await Member.GetMemberAPI().then((response) => {
      // console.log("OCCUPATIONS", response.data.data.occupations);
      const member = response.data.data.member;
      console.log("member", member);
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: member?.name,
        email: member?.email,
        phone: member?.phoneNo,
        nik: member?.nik,
      }));
    });
  }

  // --------- GET MEMBER DATA ---------

  // --------- USE EFFECT ---------
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getWorkLocations();
    getOccupations();
    getMember();
  }, []);

  const isJobValid = formData.job;
  const isJobLocationValid = formData.jobLocation;

  return (
    <div className="form_wrapper">
      <div className="form_container">
        <Form
          className="form_group_registrations d-flex flex-column"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Form.Group
            className="mt-3 mb-3 form_input_step"
            controlId="formname"
          >
            <div className="form_input_section">
              <p className="my-auto input_label">Nama Lengkap</p>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Masukan nama sesuai KTP (Contoh: Michael Angelo)"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Tolong masukan nama anda
                </Form.Control.Feedback>
              </InputGroup>
            </div>

            <div className="form_input_section">
              <p className="my-auto input_label">NIK</p>
              <InputGroup hasValidation>
                <Form.Control
                  type="number"
                  placeholder="Masukan NIK 16 digit (Contoh: 123456781011121)"
                  name="nik"
                  value={formData.nik}
                  onChange={handleInputChange}
                  disabled
                />

                <Form.Control.Feedback type="invalid">
                  Tolong masukan NIK anda
                </Form.Control.Feedback>
              </InputGroup>
            </div>
            <div className="form_input_section d-flex flex-md-row flex-column gap-3">
              <div className="col-md-6 col-12">
                <p className="my-auto input_label">Tempat Lahir</p>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Masukan Tempat Lahir (Contoh: Denpasar)"
                    name="placeOfBirth"
                    value={formData.placeOfBirth}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Tolong masukan tempat lahir anda
                  </Form.Control.Feedback>
                </InputGroup>
              </div>
              <div className="col-md-5">
                <p className="my-auto input_label">Tanggal Lahir</p>
                <InputGroup hasValidation>
                  <Form.Control
                    type="date"
                    placeholder="Max Age 65 y.o"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Tolong masukan tanggal lahir anda
                  </Form.Control.Feedback>
                </InputGroup>
              </div>
            </div>
            <div className="form_input_section">
              <p className="my-auto input_label">Nama Ibu Kandung</p>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Masukan Nama Ibu Kandung (Contoh: Papper Pots)"
                  name="motherName"
                  value={formData.motherName}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Tolong masukan nama ibu kandung anda
                </Form.Control.Feedback>
              </InputGroup>
            </div>
            <div className="form_input_section">
              <p className="my-auto input_label">Nomor Whatsapp Aktif</p>

              <InputGroup hasValidation>
                <Form.Control
                  type="number"
                  placeholder="Masukan Nomor Whatsapp Aktif (Contoh: 08212312849213)"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  disabled
                />

                <Form.Control.Feedback type="invalid">
                  Tolong masukan nomor telepon anda
                </Form.Control.Feedback>
              </InputGroup>
            </div>
            <div className="form_input_section d-flex flex-md-row flex-column ">
              <div className="col-12">
                <p className="my-auto input_label">Email</p>
                <InputGroup hasValidation>
                  <Form.Control
                    type="email"
                    placeholder="Masukan Email (Contoh: saya@gmail.com)"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    disabled
                  />

                  <Form.Control.Feedback type="invalid">
                    Tolong masukan email anda
                  </Form.Control.Feedback>
                </InputGroup>
              </div>
            </div>
            <div className="form_input_section">
              <p className="my-auto input_label">Pilih Pekerjaan</p>
              <InputGroup hasValidation>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="secondary"
                    className="w-100 text-start bg-transparent text-dark d-flex justify-content-between align-items-center"
                  >
                    {valueJob === "" ? "Pilih Pekerjaan" : valueJob}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="w-100 text-start p-2"
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    {/* <Dropdown.Item> */}
                    <FormControl
                      autoFocus
                      type="text"
                      placeholder="Search"
                      value={searchJob}
                      onChange={(e) => setSearchJob(e.target.value)}
                    />{" "}
                    {/* </Dropdown.Item> */}
                    <Dropdown.Divider />
                    {filteredJob?.map((option, index) => (
                      <Dropdown.Item
                        key={index}
                        active={option.occupation === valueJob}
                        onClick={() => {
                          handleSelectJob(option);
                        }}
                        isInvalid={!isJobValid}
                      >
                        {option.occupation}
                      </Dropdown.Item>
                    ))}
                    {search !== "" && filteredJob?.length === 0 && (
                      <Dropdown.Item
                        disabled
                      >{`${search} Pekerjaan tidak ditemukan`}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control.Feedback type="invalid">
                  Tolong konfirmasi pilih lokasi pekerjaan anda
                </Form.Control.Feedback>
              </InputGroup>
            </div>
            <div className="form_input_section">
              <p className="my-auto input_label">Lokasi</p>
              <InputGroup hasValidation>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="secondary"
                    className="w-100 text-start bg-transparent text-dark d-flex justify-content-between align-items-center"
                  >
                    {value === "" ? "Pilih Lokasi Pekerjaan" : value}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="w-100 text-start p-2"
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    {/* <Dropdown.Item> */}
                    <FormControl
                      autoFocus
                      type="text"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />{" "}
                    {/* </Dropdown.Item> */}
                    <Dropdown.Divider />
                    {filteredOptions?.map((option, index) => (
                      <Dropdown.Item
                        key={index}
                        active={option.location === value}
                        onClick={() => {
                          handleSelect(option);
                        }}
                        isInvalid={!isJobLocationValid}
                      >
                        {option.location}
                      </Dropdown.Item>
                    ))}
                    {search !== "" && filteredOptions?.length === 0 && (
                      <Dropdown.Item
                        disabled
                      >{`${search} lokasi tidak ditemukan`}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control.Feedback type="invalid">
                  Tolong konfirmasi pilih lokasi pekerjaan anda
                </Form.Control.Feedback>
              </InputGroup>
            </div>
            <div className="form_input_section d-flex flex-column flex-xl-row gap-2">
              <div className="upload_container">
                <p className="my-auto input_label">Upload foto KTP</p>
                <Form.Control
                  type="file"
                  required
                  onChange={handleFileInputChange}
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <Form.Control.Feedback type="invalid">
                  Ukuran file harus kurang dari 2MB
                </Form.Control.Feedback>
                <p className="alert_file_size mt-1">
                  Ukuran file max. 2 MB (Tipe file : .png , .jpg , jpeg){" "}
                </p>
                <p className="alert_file_size"></p>
                <div className="example_upload d-flex flex-xl-row flex-column justify-content-center align-items-center gap-2 p-3">
                  <div
                    className={`photo_left col-xl-6 col-12 p-3  d-flex flex-column justify-content-center align-items-center ${
                      previewUrl === null ? "notActive" : ""
                    }`}
                  >
                    <img
                      src={previewUrl || CloudBrowse}
                      className="photo_registrant"
                      alt=""
                      style={{ maxWidth: "100%" }}
                    />
                    <p className="my-auto input_label"> Preview Foto KTP</p>
                  </div>
                  <div className="photo_right  col-xl-6 col-12  d-flex flex-column justify-content-center align-items-center">
                    <p className="my-auto input_label"> Contoh Foto KTP</p>

                    <img
                      src={PhotoKTP}
                      className="w-50 photo_example "
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form_input_section">
              <p className="my-auto input_label">Upload foto selfie KTP</p>
              <Form.Control
                type="file"
                required
                onChange={handleFileInputChangeSelfie}
                accept=".jpg,.jpeg,.png,.pdf"
              />
              <Form.Control.Feedback type="invalid">
                Ukuran file max. 2 MB (Tipe file : .png , .jpg , jpeg){" "}
              </Form.Control.Feedback>
              <p className="alert_file_size">
                {" "}
                Ukuran file max. 2 MB (Tipe file : .png , .jpg , jpeg){" "}
              </p>
              <div className="example_upload d-flex flex-xl-row flex-column justify-content-center align-items-center gap-1 p-0">
                <div
                  className={`photo_left  col-xl-6 col-12 p-3  d-flex flex-column justify-content-center align-items-center ${
                    previewUrlSelfie === null ? "notActive" : ""
                  }`}
                >
                  <img
                    src={previewUrlSelfie || CloudBrowse}
                    className="photo_registrant"
                    alt=""
                    style={{ maxWidth: "100%" }}
                  />
                  <p className="my-auto input_label">
                    {" "}
                    Preview Foto Selfie KTP
                  </p>
                </div>
                <div className="photo_right  col-xl-6 col-12  d-flex flex-column justify-content-center align-items-center">
                  <p className="my-auto input_label"> Contoh Selfie Foto KTP</p>
                  <img
                    src={PhotoKTPSelfie}
                    className="w-100 photo_example "
                    alt=""
                  />
                </div>
              </div>
            </div>
            <p className="input_label mt-5 fs-4">
              Total biaya pendaftaran ={" "}
              <strong className="text-danger">
                {pathname.includes("bpu-3-program") ? "Rp 36.800" : "Rp 16.800"}
              </strong>
            </p>
          </Form.Group>
          <div className="btn_container d-flex gap-3">
            <Button
              variant="primary bg-transparent border-2"
              type="button"
              onClick={onBack}
              style={{
                color: "#142657",
                borderColor: "#142657",
                borderRadius: "10px",
                height: "49px",
              }}
            >
              Kembali
            </Button>
            <Button
              variant="primary"
              type="submit"
              style={{
                background: "#142657",
                borderRadius: "10px",
                height: "49px",
              }}
            >
              Kirim
            </Button>
          </div>
        </Form>

        {/* MODAL BPJS MEMBER REGISTRATIONS */}
        <RegistrationModal
          show={modalShow}
          setShow={setModalShow}
          dataRegistration={formData}
        />
      </div>
      <ToastContainer />
    </div>
  );
}
