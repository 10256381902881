import React from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import { Form } from "react-router-dom";

export default function PaymentProof() {
  const navigation = useNavigate();

  function onBack() {
    navigation("/auth/payment-detail");
  }
  return (
    <div>
      <Form>
        <Form.Group className="mb-3 mt-3">
          <Form.Label>Metode Pembayaran</Form.Label>
          <Form.Select className="input_field">
            <option>Pilih Metode Pembyaran</option>
            <option>QRIS</option>
            <option>Bank Transfer</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Upload Bukti Pembayaran</Form.Label>
          <Form.Control type="file" />
          <p className="mt-2">Ukuran file maks 2mb</p>
        </Form.Group>
      </Form>
      <div className="d-flex gap-2">
        <Button
          variant="primary"
          type="button"
          onClick={onBack}
          className="mt-3 border-2 bg-transparent col-6"
          style={{
            borderRadius: "10px",
            height: "49px",
            color: "#54B435",
            borderColor: "#54B435",
          }}
        >
          Back
        </Button>
        <Button
          variant="primary"
          type="button"
          //   onClick={onNext}
          className="mt-3 border-0 col-6"
          style={{
            background: "#54B435",
            borderRadius: "10px",
            height: "49px",
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
