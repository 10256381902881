import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import QrisExample from "../../../assets/icons/QrisExample.png";
import SuccessModal from "../../../component/common/modal/successModal/SuccessModal";
import CloudBrowse from "../../../assets/cloudBrowse.png";

import "./PaymentDetail.css";
import { toast, ToastContainer } from "react-toastify";
import { Member } from "../../../services/registration/Member";
import axios from "axios";
import { NumericFormat } from "react-number-format";

export default function PaymentDetail() {
  const getPaymentId = useParams();
  const dataFetchedRef = useRef(false);
  const navigator = useNavigate();
  const [loading, setLoading] = useState(false);
  const [paymentCheck, setPaymentCheck] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  console.log("paymet", paymentCheck);
  console.log("id", getPaymentId);

  const [formData, setFormData] = useState({
    proof: {} as File,
    method: "",
  });

  const [detailTransaction, setDetaiTransaction] = useState({
    status: "",
    amount: 0,
  });

  const handleCheckboxChange = (isChecked: boolean) => {
    setPaymentCheck(isChecked);
  };

  // HANDLE CHANGE FOR INPUT
  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const allowedExtensions = ["png", "jpg", "jpeg"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
      const isExtensionAllowed = allowedExtensions.includes(fileExtension);

      if (!isExtensionAllowed) {
        toast.error("Hanya file PNG, JPG, dan JPEG yang diperbolehkan");
        setFormData({ ...formData, proof: {} as File });
        setPreviewUrl(CloudBrowse);
      } else if (file.size > 2 * 1024 * 1024) {
        toast.error("Ukuran file harus dibawah 2 MB");
        setFormData({ ...formData, proof: {} as File });
        setPreviewUrl(CloudBrowse);
      } else {
        setFormData({ ...formData, proof: file });
        const previewUrl = URL.createObjectURL(file);
        setPreviewUrl(previewUrl);
      }
    }
  };

  // END OF HANDLE CHANGE

  // GET PAYMENT DETAIL FOR API (API INTEGRATION)
  async function getPaymentDetail() {
    const paymentId: string = getPaymentId?.paymentId?.toString()
      ? getPaymentId?.paymentId?.toString()
      : "";
    await Member.GetDetailTransactionAPI(parseInt(paymentId)).then(
      (response) => {
        const getDetailTransaction = response?.data?.data?.payment;
        console.log("detail transaction", getDetailTransaction);
        setDetaiTransaction({
          amount: getDetailTransaction?.amount,
          status: getDetailTransaction.status,
        });
      }
    );
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getPaymentDetail();
  }, []);

  // HANDLE SUBMIT FUNCTION
  const handleSubmit = async () => {
    const paymentId: string = getPaymentId?.paymentId?.toString()
      ? getPaymentId?.paymentId?.toString()
      : "";

    setLoading(true); // LOADING

    // VALIDATION FOR INPUT IMAGE
    if (formData.proof && !formData.method) {
      setLoading(false);

      console.log("no upload data");
      toast.error(
        "Mohon pastikan anda telah men Upload bukti pembayaran dan pastikan anda telah memilih metode pembayaran"
      );
      return; // Stop further execution
    } else {
      console.log("form complete");
      const data = new FormData();
      data.append("proof", formData?.proof);
      data.append("method", formData?.method);

      // Upload payment proff
      await Member.UploadPaymentProodAPI(data, parseInt(paymentId))
        .then((response) => {
          setLoading(false);
          console.log("response", response);
          toast.success("Data bukti pembayaran ada telah berhasil di kirim");
          setTimeout(() => {
            setModalShow(true);
          }, 3000);
        })
        .catch((error: any) => {
          setLoading(false);
          console.error("error response", error.response);
          console.error("error msg", error.response.data.message);
          console.error("error ", error);

          const { message } = error?.response?.data?.message;
          // Handle API error
          if (axios.isAxiosError(error) && error.response) {
            setLoading(false);
            console.error("API error:", message);
            toast.error(message);
          } else {
            setLoading(false);
            console.error("Other error:", error);
            toast.error(message);
          }
        });
    }
  };

  function onBack() {
    navigator("/users/dashboard");
  }
  return (
    <div className="payment_detail">
      <div className="payment_section">
        <p className="payment_section_title">Total pembayaran :</p>
        <p className="detail_payment" style={{ color: "#ce3838" }}>
          {detailTransaction?.amount ? (
            <>
              Rp &nbsp;
              <NumericFormat
                value={detailTransaction?.amount}
                prefix=" "
                decimalScale={1}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
              />
            </>
          ) : (
            <>loading...</>
          )}
        </p>
      </div>
      <div className="payment_section">
        <p className="payment_section_title">
          Transfer melalui rekening dibawah ini :
        </p>
        <p className="detail_payment">809283168219</p>
      </div>
      <div className="payment_section">
        <p className="payment_section_title">Nama Bank :</p>
        <p className="detail_payment">BNI</p>
      </div>
      <div className="payment_section">
        <p className="payment_section_title">Nama pemilik akun bank :</p>
        <p className="detail_payment">Lorem ipsum</p>
      </div>
      <div className="payment_section">
        <p className="payment_section_title">Status :</p>
        <p className="detail_payment fw-bold">
          {detailTransaction?.status || "Loading..."}
        </p>
      </div>
      <div className="payment_section_qris d-flex flex-column">
        <p className="payment_section_title text-md-start text-center d-flex">
          Pembayaran melalui QRIS
        </p>
        <div className="qris_wrapper d-flex flex-md-row flex-column mb-3">
          <div
            className="qris_section col-8"
            style={{
              maxWidth: "209px",
              maxHeight: "209px",
              borderRadius: "10px",
            }}
          >
            <img
              src={QrisExample}
              className="qris_barcode"
              alt=""
              style={{
                maxWidth: "209px",
                maxHeight: "209px",
                borderRadius: "10px",
              }}
            />
          </div>
          <div className="qris_detail ms-md-4 ms-0 d-flex flex-column gap-0">
            <p className="payment_section_title ">Nama pemilik akun bank :</p>
            <p className="detail_payment ">Lorem ipsum</p>
            <p className="payment_section_title"> NMID:</p>
            <p className="detail_payment">81202139172012</p>
          </div>
        </div>
        <div className="payment_section mb-2">
          {paymentCheck === false && (
            <Button
              type="button"
              variant="primary border-0"
              onClick={() => {
                if (paymentCheck === false) {
                  setPaymentCheck(true);
                } else {
                  setPaymentCheck(false);
                }
              }}
              style={{
                background: "#142657",
                borderRadius: "10px",
                height: "49px",
              }}
            >
              Sudah Melakukan Pembayaran
            </Button>
          )}
        </div>
        {!paymentCheck ? (
          <></>
        ) : (
          <>
            <Form>
              <Form.Group className="mb-3 mt-3">
                <Form.Label>Metode Pembayaran</Form.Label>
                <Form.Select
                  className="input_field"
                  onChange={(e) =>
                    setFormData({ ...formData, method: e.target.value })
                  }
                >
                  <option>Pilih Metode Pembyaran</option>
                  <option value="Qris">QRIS</option>
                  <option value="Bank Transfer">Bank Transfer</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Upload Bukti Pembayaran</Form.Label>
                <Form.Control type="file" onChange={handleFileInputChange} />
                <p className="mt-2">
                  {" "}
                  Ukuran file max. 2 MB (Tipe file : .png , .jpg , jpeg){" "}
                </p>
              </Form.Group>
            </Form>
            <div className="btn_container d-flex gap-3">
              <Button
                variant="primary bg-transparent border-2"
                type="button"
                onClick={() => {
                  if (paymentCheck === true) {
                    setPaymentCheck(false);
                    // console.log("correct data", checkedAllData);
                  } else {
                    setPaymentCheck(true);
                    // console.log("correct data", checkedAllData);
                  }
                }}
                style={{
                  // background: "#54B435",
                  color: "#142657",
                  borderColor: "#142657",
                  borderRadius: "10px",
                  height: "49px",
                }}
              >
                Kembali
              </Button>
              {loading === true ? (
                <>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={handleSubmit}
                    style={{
                      background: "#142657",
                      borderRadius: "10px",
                      height: "49px",
                    }}
                    disabled
                  >
                    Mengirim...
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={handleSubmit}
                    style={{
                      background: "#142657",
                      borderRadius: "10px",
                      height: "49px",
                    }}
                  >
                    Kirim
                  </Button>
                </>
              )}
            </div>
          </>
        )}
      </div>
      {paymentCheck === false && (
        <div className="btn_container d-flex gap-3">
          <Button
            variant="primary bg-transparent border-2"
            type="button"
            onClick={onBack}
            style={{
              // background: "#54B435",
              color: "#142657",
              borderColor: "#142657",
              borderRadius: "10px",
              height: "49px",
            }}
          >
            Kembali
          </Button>
        </div>
      )}
      <div className="d-flex mx-auto">
        <SuccessModal show={modalShow} setShow={setModalShow} />
      </div>
      <ToastContainer />
    </div>
  );
}
