import { motion, useAnimate, useInView } from "framer-motion";
import HugeCard from "./HugeCard";
import InformationSection from "./InformationSection";
import VerticalTimeline from "./VerticalTimeline";
const NewClaimPage = () => {
  return (
    <>
      {/* Single css file imports do not work apparently, these li's and ul's affect globally if put in a file */}
      <style>
        {`
          .tabs {
            flex-grow: 1;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            flex-wrap: nowrap;
            width: 100%;
          }

          ul,
          li {
            list-style: none;
            padding: 0;
            margin: 0;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 1rem;
          }

          ul {
            display: flex;
            width: 50%;
          }

          li {
            border-radius: 5px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            width: 100%;
            padding: 1.2rem 1.2rem;
            position: relative;
            background: white;
            cursor: pointer;
            height: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            min-width: 0;
            position: relative;
            user-select: none;
          }

          .underline {
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            height: 2px;
            z-index: 1;
            background: #0d6efd;
          }

          li.selected {
            color: #0d6efd;
          }
      `}
      </style>
      {/* Informasi Klaim huge card */}
      <HugeCard></HugeCard>
      {/* Persyaratan Klaim Section */}
      <InformationSection></InformationSection>
      {/* Alur Klaim timeline-like section */}
      <VerticalTimeline></VerticalTimeline>
    </>
  );
};

export default NewClaimPage;
