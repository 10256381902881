import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Member } from "../../../services/registration/Member";
import { ToastContainer, toast } from "react-toastify";
import SendMailModal from "../../../component/common/modal/sendMailModal/SendMailModal";
import { AuthAPI } from "../../../services/auth/Auth";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [nik, setNik] = useState<string>("");
  const [modalShow, setModalShow] = useState(false);
  const [mailRecipient, setMailRecipient] = useState("");

  const navigation = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // TODO: Add form validation

    await onForgotPass();
  };

  async function onForgotPass() {
    setLoading(true);

    try {
      const response = await Member.GetMemberByNikAPI(nik);
      setLoading(false);
      const email = response.data.data.member.email;
      setMailRecipient(email);
      setModalShow(true);
    } catch (error: any) {
      setLoading(false);
      const { message } = error.response.data;
      toast.error(message);
    }
  }

  async function onConfirmSendLink() {
    setModalShow(false);
    setLoading(true);
    try {
      const response = await AuthAPI.ForgotPasswordAPI({
        email: mailRecipient,
        nik,
      });
      const message = response.data.data.message;
      const toastId = toast.success(message);
      toast.onChange((toastItem) => {
        if (toastItem.id === toastId && toastItem.status === "removed") {
          navigation("/auth/login");
        }
      });
    } catch (error: any) {
      const { message } = error.response.data;
      toast.error(message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Group className="" controlId="formBasicEmail">
          <p className="input_label">NIK</p>
          <Form.Control
            className="form-control input_field_nik"
            type="number"
            placeholder="Masukan NIK anda 16 Digit"
            name="nik"
            value={nik}
            onChange={(e) => {
              setNik(e.target.value);
            }}
          />
        </Form.Group>
        <a className="forgot-pass mt-5" href="/auth/login">
          Kembali ke halaman login ?
        </a>

        {!loading ? (
          <Button
            type="submit"
            className="btn_login w-100 mt-3 border-0"
            // onClick={onForgotPass}
            style={{
              background: "#142657",
              borderRadius: "10px",
              height: "49px",
            }}
          >
            Reset Password
          </Button>
        ) : (
          <Button
            type="submit"
            className="btn_login w-100 mt-3 border-0"
            // onClick={onLogin}
            style={{
              background: "#142657",
              borderRadius: "10px",
              height: "49px",
            }}
            disabled
          >
            Loading...
          </Button>
        )}
      </Form>

      <SendMailModal
        show={modalShow}
        email={mailRecipient}
        setShow={setModalShow}
        onClickSend={onConfirmSendLink}
      />
      <ToastContainer />
    </>
  );
}
