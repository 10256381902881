// sign up type
export type SignUpType = {
  nik: string;
  email: string;
  password: string;
  name: string;
  phoneNo: string;
};

export interface SignUpModel {
  signUpData: SignUpType;
}

export const defaultSignUp: SignUpModel = {
  signUpData: {
    nik: "",
    password: "",
    name: "",
    phoneNo: "",
    email: "",
  },
};
