import axios from "axios";
import { config } from "../../../config/config";
import { GetToken } from "../../utils/redux/AuthCRUD";

export const API_URL =
  config.SERVER_ENV === "staging"
    ? config.API_URL_STAGING
    : config.API_URL_PROD;
const GET_PACKAGES_URL = `${API_URL}/programs`;

export class Programs {
  public static async GetProgramsAPI() {
    return axios.get(GET_PACKAGES_URL, {
      headers: {
        Authorization: `Bearer ${GetToken()}`,
      },
    });
  }
}
