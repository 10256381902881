import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PackagesCard from "../../../component/card/PackagesCard";

export default function PackagesOptions() {
  const navigator = useNavigate();

  function onBack() {
    navigator("/auth/sign-up");
  }

  return (
    <div>
      <PackagesCard />
      {/* <Button
        className="mt-3"
        variant="primary col-2 bg-transparent border-2"
        type="button"
        onClick={onBack}
        style={{
          // background: "#54B435",
          color: "#142657",
          borderColor: "#142657",
          borderRadius: "10px",
          height: "49px",
          width: "auto",
        }}
      >
        Kembali
      </Button> */}
    </div>
  );
}
