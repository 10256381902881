import { AnimatePresence, motion, useAnimate, useInView } from "framer-motion";
import { useState } from "react";
import { Accordion } from "react-bootstrap";

interface FAQPageQuestions {
  question: string;
  answerTitle: string;
  answerContent?: string;
}

const questions: FAQPageQuestions[] = [
  {
    question: "Apa yang dimaksud BPJS Perisai?",
    answerTitle: "BPJS Perisai.",
    answerContent:
      "BPJS ketenagakerjaan Perisai atau Penggerak Jaminan Sosial Indonesia adalah sistem jaminan yang memastikan akses jaminan sosial bagi pekerja, khususnya yang bekerja secara informal. Sistem Jaminan Sosial Nasional diatur oleh Undang-undang untuk melindungi semua pekerja Indonesia. BPJS Ketenagakerjaan adalah Badan Penyelenggara yang ditugaskan memberikan perlindungan sesuai undang-undang.",
  },
  {
    question: "Apa itu Baliola Parisai? ",
    answerTitle: "Baliola Perisai.",
    answerContent:
      "Baliola adalah agen resmi dari BPJS Perisai. Sehingga masyarakat dapat mendaftar melalui perusahaan dan agen-agen dari Baliola.",
  },
  {
    question: "Bagaimana mendaftar menjadi anggota perisai Baliola?",
    answerTitle: "Cara pendaftaran anggota perisai Baliola.",
    answerContent:
      "Cara mendaftar untuk menjadi perserta Baliola Perisai sangatlah mudah, pada halaman web di tombol sebelah kanan atas yang bertuliskan Daftar, silahkan di klik dan mulai proses pendaftaran dengan membubuhkan data pribadi yang diperlukan dan ikuti prosesnya.",
  },
  {
    question: "Berapa lama proses pendaftaran?",
    answerTitle: "Durasi proses pendaftaran anggota perisai Baliola.",
    answerContent:
      "Proses pendaftaran setelah data di submit akan melalui proses veriikasi data pribadi yang bertujuan memastikan kebenaran, setelah itu proses pembayaran iuran pertama dan penerbitan kartu anggota.",
  },
  {
    question: "Apakah bisa membuat akun lebih dari satu?",
    answerTitle: "Tidak.",
    answerContent:
      "Akun perisai akan terikut ke kartu tanda penduduk, sehingga 1 KTP hanya bisa memiliki 1 akun perisai.",
  },
];

function FAQPage() {
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(0);
  const [scope, animate] = useAnimate();
  const isinView = useInView(scope, { once: true });
  return (
    <motion.div
      style={{
        minHeight: "60vh",
      }}
      initial={{ opacity: 0 }}
      animate={
        isinView ? { opacity: 1, transition: { delay: 0 } } : { opacity: 0 }
      }
      transition={{ duration: 1 }}
      ref={scope}
    >
      {/* Single css file imports do not work apparently, these li's and ul's affect globally if put in a file */}
      <style>
        {`
          .tabs {
            flex-grow: 1;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            flex-wrap: nowrap;
            width: 100%;
          }

          ul,
          li {
            list-style: none;
            padding: 0;
            margin: 0;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 1rem;
          }

          ul {
            display: flex;
            width: 50%;
          }

          li {
            border-radius: 5px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            width: 100%;
            padding: 1.2rem 1.2rem;
            position: relative;
            background: white;
            cursor: pointer;
            height: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            min-width: 0;
            position: relative;
            user-select: none;
          }

          .underline {
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            height: 2px;
            z-index: 1;
            background: #0d6efd;
          }

          li.selected {
            color: #0d6efd;
          }
      `}
      </style>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
      />
      {/* depending on the mobile viewport, render this or that */}{" "}
      {/* TODO! need to realign the h1 */}
      <h1
        style={{
          fontWeight: "bold",
          textAlign: "left",
          fontSize: "2rem",
          marginTop: "8rem",
          marginLeft: "3rem",
          marginBottom: "2rem",
        }}
      >
        Frequently Asked Questions
      </h1>
      {window.innerWidth < 768 ? (
        // we'll just use the normal accordion for mobile
        <div
          style={{
            marginBottom: "8rem",
          }}
        >
          <Accordion defaultActiveKey="0">
            {questions.map((question, index) => {
              return (
                <Accordion.Item key={index} eventKey={index.toString()}>
                  <Accordion.Header>{question.question}</Accordion.Header>
                  <Accordion.Body>{question.answerContent}</Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      ) : (
        <div style={{ marginBottom: "8rem" }}>
          {/* main section*/}
          <div
            className="container"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "1rem",
              marginTop: "2rem",
            }}
          >
            {/* left side questions */}
            {/* to animate the moving background from one div to another */}
            <motion.div
              className="container"
              layout
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              {questions.map((question, index) => {
                return (
                  <div className="container" key={index}>
                    <motion.div
                      key={index}
                      // framer motion animated on hover
                      whileHover={{
                        scale: 1.1,
                        transition: { duration: 0.2 },
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderRadius: "0.5rem",
                        padding: "1rem",
                        justifyContent: "space-between",
                        backgroundColor:
                          selectedQuestion === index ? "#f5f5f5" : "",
                        transition: "background-color 0.2s ease-in-out",
                      }}
                      onClick={() => {
                        if (selectedQuestion === index) {
                          setSelectedQuestion(index);
                        } else {
                          setSelectedQuestion(index);
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "1rem",
                        }}
                      >
                        {/* Circle icon */}
                        {/* depending if selected or not, radiobutton checked or unchecked */}
                        <motion.div
                          layout // animate on appear and dissapear
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          {selectedQuestion === index ? (
                            <motion.span
                              // animate on appear and dissapear
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              className="material-symbols-outlined"
                            >
                              radio_button_checked
                            </motion.span>
                          ) : (
                            <motion.span className="material-symbols-outlined">
                              radio_button_unchecked
                            </motion.span>
                          )}
                        </motion.div>
                        <div className="fw-bold ">{question.question}</div>
                      </div>
                      <span className="material-symbols-outlined">
                        arrow_forward_ios
                      </span>
                    </motion.div>
                  </div>
                );
              })}
            </motion.div>

            {/* right side */}
            <motion.div
              className="container"
              layout
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <AnimatePresence>
                {questions.map((question, index) => {
                  return (
                    <motion.div
                      key={index}
                      // framer motion animated on hover
                      whileHover={{
                        scale: 1.1,
                        transition: { duration: 0.2 },
                      }}
                      style={{
                        // to make the height of the div the same as the questions
                        height: "100%",

                        // hidden if not selected, transitions from the left when active
                        display: selectedQuestion === index ? "flex" : "none",
                        flexDirection: "column",
                        alignItems: "center",
                        borderRadius: "0.5rem",
                        padding: "1rem",
                        justifyContent: "space-between",
                        backgroundColor:
                          selectedQuestion === index ? "#f5f5f5" : "",
                        transition: "background-color 0.2s ease-in-out",
                      }}
                      initial={{ opacity: 0, x: -1000, display: "none" }}
                      animate={{
                        x: selectedQuestion === index ? "0%" : "-100%",
                        opacity: selectedQuestion === index ? 1 : 0,
                        display: selectedQuestion === index ? "flex" : "none",
                      }}
                      transition={{ duration: 0.6 }}
                    >
                      <div className="container">
                        <h3>{question.answerTitle}</h3>
                        <p>
                          {selectedQuestion === index
                            ? question.answerContent
                            : ""}
                        </p>
                      </div>
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </motion.div>
          </div>
        </div>
      )}
      {/* <Footer /> */}
    </motion.div>
  );
}

export default FAQPage;
