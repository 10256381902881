import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import CheckMarkBlue from "../../../../assets/icons/CheckMarkBlue.png";

import "./SuccessModal.css";

export default function SuccessModal(props: any) {
  const navigator = useNavigate();

  function handleSubmit() {
    navigator("/users/dashboard");
  }

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="d-flex justify-content-center align-items-center modal-success"
      >
        <Modal.Body className="d-flex flex-column text-center ustify-content-center align-items-center p-5">
          <img
            src={CheckMarkBlue}
            alt=""
            style={{ maxWidth: "60px" }}
            className="mb-5"
          />
          <h4>Pendaftaran Berhasil</h4>
          <p className="forgot-pass">
            Pendaftaran akun berhasil dilakukan, admin sedang melakukan
            verifikasi akun anda.
          </p>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
            className="w-100"
            style={{
              background: "#142657",
              borderRadius: "10px",
              height: "49px",
              border: "0px",
            }}
          >
            Halaman Dashboard
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
