import React, { useMemo, useState } from "react";
import {
  CSSTransition,
  SwitchTransition,
  TransitionGroup,
} from "react-transition-group";
import "./Styles.css";

import BPJSLogo from "../../../assets/icons/BPJSLogo.png";
import { MembershipCard } from "../../../utils/models/UserModel";

interface Card {
  cardNumber: String;
  cardHolder: String;
  expDate: String;
  activationStatus: String;
}

function BPJSCard(props: any) {
  const [isHovered, setIsHovered] = useState(false);

  const { cardData } = props;

  console.log("card data from card page", cardData);
  console.log("card data from card page", cardData?.membershipCard?.holder);

  const downloadCard = () => {
    // Logic to download the card
    // You can implement the functionality as per your requirements
  };

  return (
    // <></>
    <>
      <div
        className={`card bpjs_card ${
          props.cardData.membershipCard !== null ? "" : "unverify"
        } d-flex flex-row p-3`}
      >
        <div className="col-8 col-sm-6 card_left d-flex flex-column">
          <div className="upper_card d-flex flex-column">
            <div className="card_header">
              <p className="title_card">KARTU PESERTA</p>
            </div>
            <div className="card_body">
              <p className="desc_card">
                {/* {cardData?.membershipCard?.holder} */}
                {cardData.membershipCard !== null
                  ? props?.cardData?.membershipCard.holder
                  : "-"}
              </p>
              <p className="desc_card">
                {" "}
                {cardData.membershipCard !== null
                  ? props?.cardData?.membershipCard.cardNo
                  : "-"}{" "}
              </p>
            </div>
          </div>
          <div className="card_footer">
            <p className="exp_card">
              {" "}
              {cardData.membershipCard !== null
                ? props?.cardData?.membershipCard.validUntil
                : "-"}{" "}
            </p>
          </div>
        </div>
        <div className="col-4 col-sm-6  card_right d-flex align-items-end justify-content-end flex-column">
          {/* <button
          type="button"
          className="btn btn-outline-danger disabled status_btn"
          style={{ maxWidth: "87px", maxHeight: "38px" }}
        >
          Failed
        </button> */}
          <img src={BPJSLogo} className="bpjs_logo_card" alt="" style={{}} />
        </div>
      </div>
    </>
    // <div
    //   className={`card bpjs_card ${
    //     props.cardData !== null ? "" : "unverify"
    //   } d-flex flex-row p-3`}
    // >
    //   <div className="col-8 col-sm-6 card_left d-flex flex-column">
    //     <div className="upper_card d-flex flex-column">
    //       <div className="card_header">
    //         <p className="title_card">KARTU PESERTA</p>
    //       </div>
    //       <div className="card_body">
    //         <p className="desc_card">
    //           {/* {props.cardData !== null
    //             ? props?.cardData?.membershipCard.holder
    //             : "-"} */}
    //         </p>
    //         <p className="desc_card">
    //           {/* {" "}
    //           {props.cardData !== null
    //             ? props?.cardData?.membershipCard.cardNo
    //             : "-"}{" "} */}
    //         </p>
    //       </div>
    //     </div>
    //     <div className="card_footer">
    //       <p className="exp_card">
    //         {/* {" "}
    //         {props.cardData !== null
    //           ? props?.cardData?.membershipCard.validUntil
    //           : "-"}{" "} */}
    //       </p>
    //     </div>
    //   </div>
    //   <div className="col-4 col-sm-6  card_right d-flex align-items-end justify-content-end flex-column">
    //     {/* <button
    //     type="button"
    //     className="btn btn-outline-danger disabled status_btn"
    //     style={{ maxWidth: "87px", maxHeight: "38px" }}
    //   >
    //     Failed
    //   </button> */}
    //     <img src={BPJSLogo} className="bpjs_logo_card" alt="" style={{}} />
    //   </div>
    // </div>
  );
}

export default BPJSCard;
