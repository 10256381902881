import React from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export default function AccountType() {
  const navigation = useNavigate();
  function onNext() {
    navigation("/auth/packages-option");
  }
  function onBack() {
    navigation("/auth/sign-up");
  }
  return (
    <div>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Tipe Akun</Form.Label>
          <Form.Select className="input_field">
            <option>Pilih Tipe Akun</option>
            <option>Peserta</option>
            <option>Web Agent</option>
          </Form.Select>
        </Form.Group>
        <Button
          variant="primary"
          type="button"
          onClick={onNext}
          className="btn_login w-100 mt-3 border-0"
          style={{
            background: "#54B435",
            borderRadius: "10px",
            height: "49px",
          }}
        >
          Next
        </Button>
        <Button
          type="button"
          onClick={onBack}
          className="btn_login w-100 mt-3 border-2 bg-transparent"
          style={{
            // background: "#54B435",
            color: "#54B435",
            borderColor: "#54B435",
            borderRadius: "10px",
            height: "49px",
          }}
        >
          Kembali ke halaman sebelumnya
        </Button>
      </Form>
    </div>
  );
}
