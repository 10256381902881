// LoginPage.tsx

import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../Auth.css";
import NIKIcons from "../../../assets/icons/NIKIcons.png";
import { defaultLogin, LoginModel } from "./LoginModel";
import { AuthAPI } from "../../../services/auth/Auth";
import axios from "axios";
// import { useDispatch } from "react-redux";
import * as auth from "../../../utils/redux/AuthCRUD";

const Login = () => {
  const [validated, setValidated] = useState(false);
  const [validatedNik, setValidatedNik] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<LoginModel>(defaultLogin);
  // const dispatch = useDispatch();

  const Login = AuthAPI;
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log("login Data", data);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      console.log("checking form");
      event.preventDefault();
      event.stopPropagation();
    }
    if (data.loginData.nik.length !== 16) {
      console.log("checking nik");
      window.alert("Nik harus 16 digit");
      setValidatedNik(false);
      return;
    }
    setValidatedNik(true);
    setValidated(true);
    console.log("Form submitted successfully");
    console.log("login Data", data);
    await onLogin();
  };

  const updateData = (fieldsToUpdate: Partial<LoginModel>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const navigation = useNavigate();

  async function storeTokenToLocal(token: string) {
    await auth.setAuthLocal(token);
  }

  async function onLogin() {
    setLoading(true);
    const loginData = {
      nik: data.loginData.nik,
      password: data.loginData.password,
    };
    await AuthAPI.LoginApi(loginData)
      .then(async (response) => {
        console.log("response", response.data.data);
        const token = response?.data?.data?.accessToken;
        await storeTokenToLocal(token);
        setLoading(false);
        setLoading(false);
        navigation("/users/dashboard");
      })
      .catch((error: any) => {
        setLoading(false);
        console.log("error", error.response);

        // Handle API error
        if (axios.isAxiosError(error) && error.response) {
          const { message } = error.response.data;

          // You can display an error message to the user here
          console.error("API error:", message);
          window.alert(message);
        } else {
          console.error("Other error:", error);
          // You can display an error message to the user here
        }
      });
  }

  return (
    <>
      <Form onSubmit={handleSubmit} validated={validated} noValidate>
        <Form.Group controlId="formBasicNIK">
          <p className="input_label">NIK</p>
          <Form.Control
            required
            className="form-control input_field_nik"
            type="number"
            placeholder="Masukan NIK anda 16 Digit"
            name="nik"
            value={data.loginData.nik} // Add the value attribute for NIK
            onChange={async (e) => {
              updateData({
                loginData: {
                  nik: e.target.value,
                  password: data.loginData.password,
                },
              });
            }}
          />
          <Form.Control.Feedback type="invalid">
            Mohon masukan NIK anda
          </Form.Control.Feedback>
          {!validatedNik && (
            <Form.Control.Feedback type="invalid">
              Panjang NIK Harus 16 digit angka
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mt-3" controlId="formBasicPassword">
          <p className="input_label">Password</p>
          <Form.Control
            required
            className="input_field_pass"
            type="password"
            placeholder="Password"
            name="password"
            onChange={async (e) => {
              updateData({
                loginData: {
                  nik: data.loginData.nik,
                  password: e.target.value,
                },
              });
            }}
          />
          <Form.Control.Feedback type="invalid">
            Mohon masukan password anda
          </Form.Control.Feedback>
        </Form.Group>
        <div className="d-flex justify-content-between">
          <Link className="create_account mt-2" to="/auth/sign-up">
            Belum memiliki akun ? &nbsp;{" "}
            <span className="fw-bolder">Daftar Sekarang</span>
          </Link>
          <Link className="forgot-pass mt-2" to="/auth/forgot-password">
            Lupa password ?{" "}
          </Link>
        </div>

        {!loading ? (
          <Button
            type="submit"
            className="btn_login w-100 mt-3 border-0"
            // onClick={onLogin}
            style={{
              background: "#142657",
              borderRadius: "10px",
              height: "49px",
            }}
          >
            Masuk
          </Button>
        ) : (
          <Button
            type="submit"
            className="btn_login w-100 mt-3 border-0"
            // onClick={onLogin}
            style={{
              background: "#142657",
              borderRadius: "10px",
              height: "49px",
            }}
            disabled
          >
            Loading...
          </Button>
        )}
      </Form>
    </>
  );
};

export default Login;
