import { motion, useAnimate, useInView } from "framer-motion";
import FormWithRecaptcha from "./Recaptcha";
import { useEffect, useState } from "react";
const ContactUs = () => {
  const [scope, animate] = useAnimate();
  const isinView = useInView(scope, { once: true });
  return (
    <motion.div
      // animate fade in on view
      initial={{ opacity: 0 }}
      animate={
        isinView ? { opacity: 1, transition: { delay: 0.75 } } : { opacity: 0 }
      }
      transition={{ duration: 1 }}
      ref={scope}
    >
      <h1 className="text-center">Hubungi Kami</h1>
      <div className="fluid-container">
        {/* all items in this container will always have the same height */}
        <div className="row d-flex justify-content-center gap-5 align-items-center">
          {/* card 1 */}
          <div
            className="col-11 col-lg-4 border border-2 p-3"
            style={{
              borderRadius: "15px",
            }}
          >
            <div
              className="card-body h-100"
              style={{
                minHeight: "500px",
              }}
            >
              {/* Google Maps Widget */}
              <iframe
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2226.646269174675!2d115.21064709029928!3d-8.636079106680144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd23f2289afc625%3A0xc86379ba09c7dd2a!2sDharma%20Negara%20Alaya%20(DNA%20Art%20%26%20Creative%20Hub%20Denpasar)!5e0!3m2!1sen!2sau!4v1684931267527!5m2!1sen!2sau"
                // responsive height and widht
                style={{
                  border: "0",
                  width: "100%",
                  height: "100%",
                  minHeight: "300px",
                  borderRadius: "15px",
                }}
                loading="lazy"
              ></iframe>
              <h2
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                Temui Kami
              </h2>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "1em",
                }}
              >
                Jl. Mulawarman, Dauh Puri Kaja, Kec. Denpasar Utara, Kota
                Denpasar, Bali 80231, Indonesia
              </p>
            </div>
          </div>
          {/* card 2 */}
          <div
            className="col-11 col-lg-4 border border-2 p-3"
            style={{
              borderRadius: "15px",
            }}
          >
            <div
              className="card-body h-100"
              style={{
                minHeight: "500px",
              }}
            >
              {/* Generic Contact Form */}
              <FormWithRecaptcha></FormWithRecaptcha>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ContactUs;
