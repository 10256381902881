import { motion, useAnimate, useInView } from "framer-motion";
import badankreatifdenpasaricon from "./assets/PartnerIcons/badankreatifdenpasar.svg";
// import baliblockchaincentericon from "./assets/PartnerIcons/baliblockchaincenter.svg";
import baliblockchaincentericon from "./assets/PartnerIcons/baliblockchaincenter.png";
import bpjskesehatanicon from "./assets/PartnerIcons/bpjskesehatan.svg";
import gekrafsicon from "./assets/PartnerIcons/gekrafs.svg";
import mandalaicon from "./assets/PartnerIcons/mandala.svg";

const OurPartners = () => {
  const [scope, animate] = useAnimate();
  const isinView = useInView(scope, { once: true });
  return (
    <motion.div
      // animate fade in on view
      initial={{ opacity: 0 }}
      animate={
        isinView ? { opacity: 1, transition: { delay: 0.5 } } : { opacity: 0 }
      }
      transition={{ duration: 1 }}
      ref={scope}
    >
      <h1 className="text-center">Our Partners</h1>
      <div className="container">
        <div className="row">
          <div className="col d-flex align-items-center justify-content-center">
            <img
              src={bpjskesehatanicon}
              alt="placeholder"
              style={{
                width: "8rem",
                height: "8rem",
                objectFit: "contain",
              }}
            />
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <img
              src={badankreatifdenpasaricon}
              alt="placeholder"
              style={{
                width: "8rem",
                height: "8rem",
                objectFit: "contain",
              }}
            />
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <img
              src={gekrafsicon}
              alt="placeholder"
              style={{
                width: "8rem",
                height: "8rem",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
        {/* custompadding for media query breakpoints for the left and right padding  */}
        <style>
          {`
          .custompadding {
            padding-left: 10vw;
            padding-right: 10vw;
          }
          @media (max-width: 900px) {
            .custompadding {
              padding-left: 2vw;
              padding-right: 2vw;
            }
          }
        `}
        </style>
        <div className="custompadding row justify-content-around">
          <div className="col d-flex align-items-center justify-content-center">
            <img
              src={mandalaicon}
              alt="placeholder"
              style={{
                width: "8rem",
                height: "8rem",
                objectFit: "contain",
              }}
            />
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <img
              src={baliblockchaincentericon}
              alt="placeholder"
              style={{
                width: "10rem",
                height: "10rem",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default OurPartners;
