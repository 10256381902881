import React, { useState } from "react";
import { Link } from "react-router-dom";
import HamburgerIcon from "../../../assets/icons/hamburger.png";
import UserIcon from "../../../assets/icons/UserIcon.png";
import MoneyIcon from "../../../assets/icons/MoneyIcon.png";
import BillIcon from "../../../assets/icons/BillIcon.png";
import ClaimIcon from "../../../assets/icons/ClaimIcon.png";
import HomeIcon from "../../../assets/icons/HomeIcon.png";
import LogoutIcon from "../../../assets/icons/LogoutIcon.png";

import "./Sidebar.css";

type SidebarProps = {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
};

export default function Sidebar(props: SidebarProps) {
  const toggleMenu = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const { isSidebarOpen, setIsSidebarOpen } = props;

  const handleLogout = () => {
    // Clear session storag

    // Clear local storage
    localStorage.clear();

    // Update the state to reflect the logout
  };

  const [isLoggedIn, setIsLoggedIn] = useState(true);

  return (
    <>
      <div className="container-fluid sidebar_container">
        <div className="row flex-nowrap row_sidebar">
          <div className="col-auto sidebar_col px-0">
            <div
              id="sidebar"
              className={`collapse  show border-end ${
                isSidebarOpen ? "sidebar_wrapper" : "sidebar-closed"
              }`}
              style={{ maxWidth: "300px" }}
            >
              <div
                id="sidebar-nav w-100"
                className="list-group border-0 rounded-0 text-sm-start min-vh-100"
              >
                <div
                  className={
                    isSidebarOpen ? `sidebar_wrapper p-3` : "sidebar-closed"
                  }
                >
                  <div
                    className="sidebar_item_wrapper_title"
                    style={{ maxWidth: "248px" }}
                  >
                    <Link
                      to="#"
                      className="sidebar_item_title d-flex text-truncate"
                      data-bs-parent="#sidebar"
                    >
                      Dashboard Peserta
                    </Link>
                    <img
                      src={HamburgerIcon}
                      data-bs-target="#sidebar"
                      data-bs-toggle="collapse"
                      onClick={toggleMenu}
                      className="ms-md-2 hamburber_icon"
                      alt=""
                      style={{ maxWidth: "14px", maxHeight: "14px" }}
                    />
                  </div>
                  <hr />
                  <div
                    className="sidebar_item_wrapper"
                    style={{ maxWidth: "248px" }}
                  >
                    <Link
                      to="/users/dashboard"
                      className="sidebar_item active d-flex text-start"
                      data-bs-parent="#sidebar"
                    >
                      <img
                        src={UserIcon}
                        className="ms-md-2 hamburber_icon "
                        alt=""
                        style={{ maxWidth: "20px" }}
                      />
                      <p className="sidebar_item_text">Account</p>
                    </Link>
                    <Link
                      to="#"
                      className="sidebar_item d-flex text-start"
                      data-bs-parent="#sidebar"
                    >
                      <img
                        src={MoneyIcon}
                        className="ms-md-2 hamburber_icon "
                        alt=""
                        style={{ maxWidth: "20px" }}
                      />
                      <p className="sidebar_item_text">Pembayaran</p>
                    </Link>
                    <Link
                      to="#"
                      className="sidebar_item d-flex text-start"
                      data-bs-parent="#sidebar"
                    >
                      <img
                        src={BillIcon}
                        className="ms-md-2 hamburber_icon "
                        alt=""
                        style={{ maxWidth: "20px" }}
                      />

                      <p className="sidebar_item_text">Tagihan</p>
                    </Link>
                    <Link
                      to="#"
                      className="sidebar_item d-flex text-start"
                      data-bs-parent="#sidebar"
                    >
                      <img
                        src={ClaimIcon}
                        className="ms-md-2 hamburber_icon "
                        alt=""
                        style={{ maxWidth: "20px" }}
                      />

                      <p className="sidebar_item_text">Klaim</p>
                    </Link>
                    <Link
                      to="/"
                      className="sidebar_item d-flex text-start"
                      data-bs-parent="#sidebar"
                    >
                      <img
                        src={HomeIcon}
                        className="ms-md-2 hamburber_icon "
                        alt=""
                        style={{ maxWidth: "20px" }}
                      />
                      <p className="sidebar_item_text">Home</p>
                    </Link>
                  </div>
                  <div
                    className="sidebar_item_footer"
                    style={{ maxWidth: "248px" }}
                  >
                    <Link
                      to="/auth/login"
                      className="sidebar_item_logout d-flex text-start"
                      data-bs-parent="#sidebar"
                      onClick={handleLogout}
                    >
                      <img
                        src={LogoutIcon}
                        className="ms-md-2 hamburber_icon "
                        alt=""
                        style={{ maxWidth: "20px" }}
                      />
                      <p className="sidebar_item_text mt-3">Logout</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
