import { motion, useAnimate, useInView } from "framer-motion";
import InformasiKlaim from "./assets/InformasiKlaimIllustration.png";
// A huge, responsive card with an illustration image, and title
const HugeCard = () => {
  const [scope, animate] = useAnimate();
  const isinView = useInView(scope, { once: true });
  return (
    <>
      {/* custom styling media queries for left and right padding */}
      <style>
        {`
        .custommargin {
          margin-left: 10vw;
          margin-right: 10vw;
        }
        @media (max-width: 768px) {
          .custommargin {
            margin-left: 0;
            margin-right: 0;
          }
        }
        `}
      </style>
      <motion.div // animate fade in on view
        className="card custommargin mt-5"
        initial={{ opacity: 0 }}
        animate={
          isinView ? { opacity: 1, transition: { delay: 0 } } : { opacity: 0 }
        }
        transition={{ duration: 1 }}
        ref={scope}
        style={{
          borderRadius: "15px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <style>
          {`
            .tst {
              border: 1px solid red;
            }
            .hugecardheader {
              text-align: left;
            }
            @media (max-width: 765px) {
              .hugecardheader {
                text-align: center;
                justify-content: center;
                align-items: center;
              }
            }
      `}
        </style>
        <div className="row g-0 d-flex align-items-center">
          <div className="col-md-4 mx-md-3">
            <img
              src={InformasiKlaim}
              className="img-fluid rounded-start"
              alt="..."
            ></img>
          </div>
          <div className="col-md-4 px-md-3">
            <div className=" d-flex align-items-center justify-content-center">
              <h5
                className="fw-bolder hugecardheader"
                style={{
                  // clamping the fontsize between 3em and 4em
                  fontSize: "clamp(3em, 4vw, 4em)",
                  maxWidth: "400px",
                }}
              >
                INFORMASI KLAIM
              </h5>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default HugeCard;
