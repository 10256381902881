import { useState } from "react";
import { initialTabs as tabs } from "./InformationSectionData";
import { motion, AnimatePresence, useAnimate, useInView } from "framer-motion";
import { Accordion } from "react-bootstrap";
// import "../NewLandingPage/specialunderline.css";

const InformationSection = () => {
  const [scope, animate] = useAnimate();
  const isinView = useInView(scope, { once: true });
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  return (
    <motion.div // animate fade in on view
      className="container"
      initial={{ opacity: 0 }}
      animate={
        isinView ? { opacity: 1, transition: { delay: 0.25 } } : { opacity: 0 }
      }
      transition={{ duration: 1 }}
      ref={scope}
    >
      {/* <style>{customAboutUsStyling}</style> */}
      <h1
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "5rem",
        }}
      >
        Persyaratan Klaim
      </h1>
      <div className="p-4 d-flex flex-column justify-content-center align-items-center">
        <nav className="container w-75 d-flex flex-column justify-content-center align-items-center">
          <ul
            style={{ width: "fit-content" }}
            className="row justify-content-center"
          >
            {tabs.map((tab) => (
              <li
                key={tab.labelTitle}
                className={
                  tab === selectedTab
                    ? "col-auto selected text-center"
                    : "col-auto text-center"
                }
                onClick={() => setSelectedTab(tab)}
              >
                {/* label that stays one line */}
                <span
                  // style={{
                  //   whiteSpace: "nowrap",
                  // }}
                  style={{
                    // clamped
                    fontSize: "clamp(0.7rem, 1.5vw, 1rem)",
                  }}
                >
                  {tab.labelTitle}
                </span>
                {tab === selectedTab ? (
                  <motion.div className="underline" layoutId="underline" />
                ) : null}
              </li>
            ))}
          </ul>
        </nav>
        <main>
          <AnimatePresence mode="wait">
            <motion.div
              className="mt-4"
              key={selectedTab ? selectedTab.labelTitle : "empty"}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
              // to center the card on the page\
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {selectedTab ? (
                // if its mobile, the picture is on the top
                <div>
                  {window.innerWidth < 768 ? (
                    <div className="mx-3 ">
                      <div className="row g-0">
                        <div className="col-md-6 col-sm-4 col-12 d-flex justify-content-center align-items-center">
                          <img
                            src={selectedTab.image}
                            className="img-fluid"
                            style={{
                              width: "45vw",
                              height: "45vw",
                              objectFit: "fill",
                            }}
                            alt="..."
                          ></img>
                        </div>
                        <div className="col-md-6 col-sm-8 col-12">
                          {/* Accordion component that holds all that data, and one is always selected no matter what */}
                          <Accordion defaultActiveKey="0">
                            {selectedTab.content.map((content, index) => (
                              <Accordion.Item
                                eventKey={index.toString()}
                                key={index}
                              >
                                <Accordion.Header>
                                  {content.innerTitle}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p
                                    style={{ whiteSpace: "pre-wrap" }}
                                    dangerouslySetInnerHTML={{
                                      __html: content.innerContent,
                                    }}
                                  ></p>
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                          {/* <div className="card-body">
                        <h2
                          className="card-title fs-md-3 fs-sm-2"
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "2.5rem",
                          }}
                        >
                          <div>helo</div>
                          {selectedTab.content[0].innerTitle}
                        </h2>
                        <p
                          className="card-text fs-md-2 fs-sm-1"
                          style={{ textAlign: "left", fontSize: "1.2rem" }}
                        >
                          {selectedTab.content[0].innerContent}
                        </p>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mx-3 ">
                      <div
                        className="row g-0"
                        style={{
                          minWidth: "90vw",
                        }}
                      >
                        <div className="col-md-6 col-sm-8 col-12" style={{}}>
                          {/* Accordion component that holds all that data, and one is always selected no matter what */}
                          <Accordion defaultActiveKey="0">
                            {selectedTab.content.map((content, index) => (
                              <Accordion.Item
                                eventKey={index.toString()}
                                key={index}
                              >
                                <Accordion.Header>
                                  {content.innerTitle}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: content.innerContent,
                                    }}
                                  ></div>
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                          {/* <div className="card-body">
                        <h2
                          className="card-title fs-md-3 fs-sm-2"
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "2.5rem",
                          }}
                        >
                          <div>helo</div>
                          {selectedTab.content[0].innerTitle}
                        </h2>
                        <p
                          className="card-text fs-md-2 fs-sm-1"
                          style={{ textAlign: "left", fontSize: "1.2rem" }}
                        >
                          {selectedTab.content[0].innerContent}
                        </p>
                      </div> */}
                        </div>
                        <div className="col-md-6 col-sm-4 col-12 d-flex justify-content-center align-items-center">
                          <img
                            src={selectedTab.image}
                            className="img-fluid"
                            style={{
                              width: "25vw",
                              height: "25vw",
                              objectFit: "fill",
                            }}
                            alt="..."
                          ></img>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                "none"
              )}
            </motion.div>
          </AnimatePresence>
        </main>
      </div>
    </motion.div>
  );
};

export default InformationSection;
