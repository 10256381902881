import React, { useState } from "react";
import NewNavbar from "../../../NewLandingPage/NewNavbar";
import NewFooter from "../../../NewLandingPage/Footer";

export interface Props {
  // title: string;
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div>
        <NewNavbar />
        {children}
      </div>
      <NewFooter />
    </div>
  );
};

export default Layout;
