interface KeuntunganUICardProps {
  title: string;
  description: string;
  icon: any;
}
const KeuntunganUICard = ({
  title,
  description,
  icon,
}: KeuntunganUICardProps) => (
  <div
    className="card h-100"
    style={{
      boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.35)",
      borderRadius: "1rem",
      padding: "1rem",
    }}
  >
    <div
      className="card-body"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "1rem",
      }}
    >
      <img src={icon} alt="..." style={{ width: "3rem" }} />
      <h5 className="card-title">{title}</h5>
      <p
        className="card-text"
        style={{
          textAlign: "left",
        }}
      >
        {description}
      </p>
    </div>
  </div>
);

export default KeuntunganUICard;
