import { useEffect, useState } from "react";
import { initialTabs as tabs } from "./aboutUsData";
import { motion, AnimatePresence, useAnimate, useInView } from "framer-motion";
import "../styling/globals.css";

const AboutUs = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [scope, animate] = useAnimate();
  const isinView = useInView(scope, { once: true });

  return (
    <motion.div
      // animate fade in on view
      initial={{ opacity: 0 }}
      animate={isinView ? { opacity: 1 } : { opacity: 0 }}
      transition={{ duration: 1 }}
      ref={scope}
    >
      <div id={"aboutus"} className="container">
        {/* <style>{customAboutUsStyling}</style> */}
        <h1
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Tentang Kami
        </h1>
        <div className="p-4 d-flex flex-column justify-content-center align-items-center">
          <nav className="w-75  d-flex flex-column justify-content-center align-items-center">
            <ul style={{ width: "fit-content" }}>
              {tabs.map((tab) => (
                <li
                  key={tab.label}
                  className={
                    tab === selectedTab ? "selected text-center" : "text-center"
                  }
                  onClick={() => setSelectedTab(tab)}
                >
                  {/* label that stays one line */}
                  <span
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {tab.label}
                  </span>
                  {tab === selectedTab ? (
                    <motion.div className="underline" layoutId="underline" />
                  ) : null}
                </li>
              ))}
            </ul>
          </nav>
          <main>
            <AnimatePresence mode="wait">
              <motion.div
                className="mt-4"
                key={selectedTab ? selectedTab.label : "empty"}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
                // to center the card on the page\
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {selectedTab ? (
                  <div className="mx-3">
                    <div className="row g-0">
                      <div className="col-md-6 col-sm-8 col-12">
                        <div className="card-body">
                          <h2
                            className="mobiletextalign card-title fs-md-3 fs-sm-2"
                            style={{
                              fontWeight: "bold",
                              fontSize: "2.5rem",
                            }}
                          >
                            {selectedTab.label}
                          </h2>
                          <p
                            className="card-text fs-md-2 fs-sm-1 mobiletextalign"
                            style={{ fontSize: "1.2rem" }}
                          >
                            {selectedTab.content}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-4 col-12 d-flex justify-content-center align-items-center">
                        <img
                          src={selectedTab.image}
                          className="img-fluid"
                          style={{
                            width: "100%",
                            height: "25vw",
                            objectFit: "fill",
                          }}
                          alt="..."
                        ></img>
                      </div>
                    </div>
                  </div>
                ) : (
                  "none"
                )}
              </motion.div>
            </AnimatePresence>
          </main>
        </div>
      </div>
    </motion.div>
  );
};

export default AboutUs;
