import { Button, Modal } from "react-bootstrap";
import CheckMark from "../../../../assets/icons/checkMark2.png";

interface Props {
  email: string;
  show: boolean
  setShow: (arg0: boolean) => void;
  onClickSend: () => void
}

export default function SendMailModal(props: Props) {
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="d-flex flex-column text-center ustify-content-center align-items-center p-5">
        <img
          src={CheckMark}
          alt=""
          style={{ maxWidth: "60px" }}
          className="mb-2"
        />
        <h4>
          Kirim tautan ke {props.email}?
        </h4>{" "}
        <p className="forgot-pass">
          Tautan untuk mengatur ulang kata sandi akan dikirimkan melalui email ke alamat email tersebut.
        </p>{" "}
        <div className="d-flex flex-sm-row flex-column gap-2 mt-3">
          {" "}
          <Button
            variant="primary bg-transparent border-2"
            onClick={() => props.setShow(false)}
            style={{
              color: "#142657",
              borderColor: "#142657",
              borderRadius: "10px",
              height: "49px",
              width: "150px",
            }}
          >
            Kembali
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={props.onClickSend}
            style={{
              background: "#142657",
              borderRadius: "10px",
              height: "49px",
              border: "0px",
              width: "150px",
            }}
          >
            Kirim{" "}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}