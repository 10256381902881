import { useEffect, useRef, useState } from "react";
import PublicRoutes from "./routing/PublicRoutes";
import "../src/app/App.css";
import { UserModel } from "./app/utils/models/UserModel";
import jwt from "jsonwebtoken";
import { GetToken } from "./app/utils/redux/AuthCRUD";
import { useNavigate } from "react-router-dom";
import { GlobalDebug } from "./config/remove-console";
import { config } from "./config/config";
import { API_URL } from "./app/services/auth/Auth";

function App() {
  const [count, setCount] = useState(0);
  const [user, setUser] = useState<UserModel>();
  // const navigate = useNavigate();
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    console.log("env", config.NODE_ENV);
    console.log("server", config.SERVER_ENV);
    console.log("server", process.env.SERVER_ENV);

    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    if (config.NODE_ENV === "development" || config.NODE_ENV === "staging") {
      console.log("env", config.NODE_ENV);
      console.log("api", API_URL);

      GlobalDebug(false, false);
      // console.log = () => {};
    }
    // const imageFetcher = new ImageFetcher();
    // setWorker(imageFetcher);
  }, []);

  return <PublicRoutes />;
}

export default App;
